import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { ArrowLeft } from 'react-feather';
import { supabase } from '../config/supabaseConfig';
import TransactionHistory from './TransactionHistory';

const Profile = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleSignOut = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      navigate('/main');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleSignIn = () => {
    navigate('/auth');
  };

  return (
    <div className="MacbookAir32" style={{
      width: '100%',
      minHeight: '100vh',
      background: 'black',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      display: 'flex',
      position: 'relative'
    }}>
      {/* Header Section */}
      <div style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '20px 40px',
        position: 'relative',
        boxSizing: 'border-box'
      }}>
        {/* Left Section with Back Button and Logo */}
        <div style={{
          display: 'flex',
          alignItems: 'center',
          gap: '24px'
        }}>
          {/* Back Button */}
          <button
            onClick={() => navigate('/main')}
            style={{
              background: 'none',
              border: 'none',
              padding: '10px',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              color: 'white'
            }}
            aria-label="Go back"
          >
            <ArrowLeft size={24} />
          </button>

          {/* Logo */}
          <div style={{
            color: 'white',
            fontSize: '35.75px',
            fontFamily: 'Recoleta',
            fontWeight: '700'
          }}>
            Grazia
          </div>
        </div>

        {/* Right Section with Email and Auth Button */}
        <div style={{
          display: 'flex',
          alignItems: 'center',
          gap: '20px'
        }}>
          {user && (
            <div style={{
              color: 'rgba(255, 255, 255, 0.7)',
              fontSize: '16px',
              fontFamily: 'Inter Display',
              fontWeight: '400'
            }}>
              {user.email}
            </div>
          )}
          <button 
            onClick={user ? handleSignOut : handleSignIn}
            style={{
              background: '#313131',
              borderRadius: '14px',
              padding: '10px 30px',
              border: 'none',
              cursor: 'pointer',
              transition: 'background 0.2s ease',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '120px'
            }}
            onMouseEnter={(e) => e.currentTarget.style.background = '#404040'}
            onMouseLeave={(e) => e.currentTarget.style.background = '#313131'}
          >
            <span style={{
              color: 'white',
              fontSize: '18px',
              fontFamily: 'Inter Display',
              fontWeight: '400'
            }}>
              {user ? 'Sign Out' : 'Sign In'}
            </span>
          </button>
        </div>
      </div>

      {/* Profile Title Section */}
      <div style={{
        width: '100%',
        padding: '60px 40px 0',
        boxSizing: 'border-box'
      }}>
        <h1 style={{
          color: 'white',
          fontSize: '63.94px',
          fontFamily: 'Inter Display',
          fontWeight: '700',
          lineHeight: '67.16px',
          margin: 0
        }}>
          My profile
        </h1>
      </div>

      {/* Content Section (for future use) */}
      <div style={{
        width: '100%',
        padding: '40px 40px',
        flex: 1,
        boxSizing: 'border-box'
      }}>
        {/* Add profile content here */}
      </div>

      <TransactionHistory />
    </div>
  );
};

export default Profile; 