import React, { useEffect } from 'react';
import { X } from 'react-feather';
import './Toast.css';

const Toast = ({ message, type = 'error', onClose, action }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 5000);

    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div className={`toast toast-${type} ${action ? 'with-action' : ''}`}>
      <div className="toast-content">
        <span className="toast-message">{message}</span>
        {action && (
          <button onClick={action.onClick} className="toast-action">
            {action.label}
          </button>
        )}
      </div>
      <button onClick={onClose} className="toast-close">
        <X size={16} />
      </button>
    </div>
  );
};

export default Toast; 