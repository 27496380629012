import React, { useState, useEffect } from 'react';
import { supabase } from '../config/supabaseConfig';
import { useAuth } from '../context/AuthContext';
import './PricingModal.css';

const PricingModal = ({ onClose }) => {
  const { user } = useAuth();
  const [billingCycle, setBillingCycle] = useState('monthly');
  const [subscriptionTiers, setSubscriptionTiers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchSubscriptionTiers();
  }, []);

  const fetchSubscriptionTiers = async () => {
    try {
      const { data, error } = await supabase
        .from('subscription_tiers')
        .select('*')
        .order('price_usd_montly', { ascending: true });

      if (error) throw error;

      setSubscriptionTiers(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching subscription tiers:', error);
      setLoading(false);
    }
  };

  const handleUpgradeClick = async (tier) => {
    try {
      if (!user?.email || !user?.id) {
        console.error('Missing user data:', { user });
        throw new Error('Missing user information');
      }

      const priceId = billingCycle === 'yearly' 
        ? tier.stripe_price_id_yearly 
        : tier.stripe_price_id_montly;

      if (!priceId) {
        throw new Error('Price ID not found for selected plan');
      }

      const requestData = {
        userId: user.id.toString(),
        userEmail: user.email.toString().toLowerCase().trim(),
        priceId: priceId,
        returnUrl: `${window.location.origin}/main`,
        successUrl: `${window.location.origin}/main`,
        cancelUrl: `${window.location.origin}/main`
      };

      console.log('Sending checkout request:', requestData);

      const response = await fetch(
        `${process.env.REACT_APP_SUPABASE_URL}/functions/v1/create-checkout-session`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${process.env.REACT_APP_SUPABASE_ANON_KEY}`,
          },
          body: JSON.stringify(requestData),
        }
      );

      const responseText = await response.text();
      console.log('Response from server:', responseText);

      if (!response.ok) {
        let errorData;
        try {
          errorData = JSON.parse(responseText);
        } catch (e) {
          errorData = { error: responseText };
        }
        throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
      }
      
      const data = JSON.parse(responseText);
      
      if (data.url) {
        window.location.href = data.url;
      } else {
        throw new Error('No checkout URL received');
      }
      
    } catch (error) {
      console.error('Error starting checkout:', error);
      alert(`Failed to start checkout: ${error.message}`);
    }
  };

  const getPrice = (tier) => {
    return billingCycle === 'yearly' 
      ? tier.price_usd_yearly 
      : tier.price_usd_montly;
  };

  const getPlans = () => {
    if (!subscriptionTiers.length) return [];

    return subscriptionTiers.map(tier => ({
      name: tier.name,
      price: getPrice(tier),
      period: billingCycle === 'yearly' ? '/year' : '/month',
      credits: `${tier.monthly_credits} Credits monthly`,
      recommended: tier.name === 'Unlimited',
      stripeProductId: tier.stripe_product_id,
      features: [
        'Everything in Basic and...',
        'Generative Video',
        'Upscale resolution',
        'Remove watermarks',
        'Credits refresh monthly',
        'Train custom AI'
      ]
    }));
  };

  // Define features for each tier type
  const getTierFeatures = (tierName) => {
    switch (tierName) {
      case 'Free':
        return [
          'Generative Video',
          '3 video projects',
          '5GB Assets storage'
        ];
      case 'Standard':
        return [
          'Everything in Free and...',
          'Generative Video',
          'Upscale resolution',
          'Remove watermarks',
          'Credits refresh monthly',
          'Train custom AI'
        ];
      case 'Pro':
        return [
          'Everything in Standard and...',
          'Create custom voices',
          '500GB Assets storage',
          'Premium support',
          'Advanced AI features'
        ];
      case 'Unlimited':
        return [
          'Everything in Pro and...',
          'Explore Mode',
          'Unlimited generations',
          'Priority support',
          'Custom solutions'
        ];
      default:
        return [];
    }
  };

  if (loading) {
    return (
      <div className="pricing-modal-overlay">
        <div className="pricing-modal">
          <div className="loading">Loading pricing plans...</div>
        </div>
      </div>
    );
  }

  return (
    <div className="pricing-modal-overlay">
      <div className="pricing-modal">
        <button className="close-button" onClick={onClose}>×</button>
        
        <h2>Choose a plan</h2>
        
        <div className="billing-toggle">
          <button 
            className={billingCycle === 'monthly' ? 'active' : ''}
            onClick={() => setBillingCycle('monthly')}
          >
            Monthly
          </button>
          <button 
            className={billingCycle === 'yearly' ? 'active' : ''}
            onClick={() => setBillingCycle('yearly')}
          >
            Yearly - 20% off
          </button>
        </div>

        <div className="plans-grid">
          {subscriptionTiers.map((tier) => (
            <div key={tier.name} className={`plan-card ${tier.name === 'Unlimited' ? 'recommended' : ''}`}>
              <div className="plan-header">
                {tier.name === 'Unlimited' && <span className="recommended-badge">RECOMMENDED</span>}
                <h3>{tier.name}</h3>
                <div className="plan-price">
                  <span className="amount">
                    ${billingCycle === 'yearly' ? tier.price_usd_yearly : tier.price_usd_montly}
                  </span>
                  <span className="period">
                    {billingCycle === 'yearly' ? '/year' : '/month'}
                  </span>
                </div>
                <p className="credits">{tier.monthly_credits} Credits monthly</p>
              </div>
              
              <button 
                className="select-plan-button"
                onClick={() => handleUpgradeClick(tier)}
              >
                {tier.name === 'Free' ? 'Current Plan' : `Get ${tier.name}`}
              </button>

              <ul className="features-list">
                {getTierFeatures(tier.name).map((feature, index) => (
                  <li key={index}>{feature}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PricingModal; 