import React, { useState, useEffect } from 'react';
import { supabase } from '../config/supabaseConfig';
import { useAuth } from '../context/AuthContext';
import { X } from 'react-feather';
import './MobilePricingPlansModal.css';

const MobilePricingPlansModal = ({ onClose }) => {
  const [subscriptionTiers, setSubscriptionTiers] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const [billingCycle, setBillingCycle] = useState('monthly');

  useEffect(() => {
    fetchSubscriptionTiers();
  }, []);

  const fetchSubscriptionTiers = async () => {
    try {
      const { data, error } = await supabase
        .from('subscription_tiers')
        .select('*')
        .order('price_usd_montly', { ascending: true });

      if (error) throw error;
      setSubscriptionTiers(data);
    } catch (error) {
      console.error('Error fetching subscription tiers:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubscribe = async (tier) => {
    try {
      if (!user?.email || !user?.id) {
        console.error('Missing user data:', { user });
        throw new Error('Missing user information');
      }

      const priceId = billingCycle === 'yearly' 
        ? tier.stripe_price_id_yearly 
        : tier.stripe_price_id_montly;

      if (!priceId) {
        throw new Error('Price ID not found for selected plan');
      }

      const requestData = {
        userId: user.id.toString(),
        userEmail: user.email.toString().toLowerCase().trim(),
        priceId: priceId,
        returnUrl: `${window.location.origin}/main`,
        successUrl: `${window.location.origin}/main`,
        cancelUrl: `${window.location.origin}/main`
      };

      console.log('Sending checkout request:', requestData);

      const response = await fetch(
        `${process.env.REACT_APP_SUPABASE_URL}/functions/v1/create-checkout-session`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${process.env.REACT_APP_SUPABASE_ANON_KEY}`,
          },
          body: JSON.stringify(requestData),
        }
      );

      const responseText = await response.text();
      console.log('Response from server:', responseText);

      if (!response.ok) {
        let errorData;
        try {
          errorData = JSON.parse(responseText);
        } catch (e) {
          errorData = { error: responseText };
        }
        throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
      }
      
      const data = JSON.parse(responseText);
      
      if (data.url) {
        window.location.href = data.url;
      } else {
        throw new Error('No checkout URL received');
      }
      
    } catch (error) {
      console.error('Error starting checkout:', error);
      alert(`Failed to start checkout: ${error.message}`);
    }
  };

  return (
    <div className="mobile-modal-overlay">
      <div className="mobile-pricing-modal">
        <button className="mobile-close-button" onClick={onClose}>
          <X size={24} />
        </button>

        <div className="mobile-pricing-header">
          <h2>Planes de Suscripción</h2>
          <p>Elige el plan que mejor se adapte a tus necesidades</p>
        </div>

        <div style={{ textAlign: 'center' }}>
          <div className="mobile-billing-toggle">
            <button 
              className={billingCycle === 'monthly' ? 'active' : ''}
              onClick={() => setBillingCycle('monthly')}
            >
              Mensual
            </button>
            <button 
              className={billingCycle === 'yearly' ? 'active' : ''}
              onClick={() => setBillingCycle('yearly')}
            >
              Anual - 20% descuento
            </button>
          </div>
        </div>

        <div className="mobile-pricing-tiers">
          {loading ? (
            <div className="mobile-loading">Loading...</div>
          ) : (
            subscriptionTiers.map((tier) => (
              <div key={tier.id} className="mobile-pricing-tier">
                <div className="mobile-tier-header">
                  {tier.name === 'Unlimited' && (
                    <span className="mobile-recommended-badge">RECOMENDADO</span>
                  )}
                  <h3>{tier.name}</h3>
                  <div className="mobile-price">
                    <span className="mobile-amount">
                      ${billingCycle === 'yearly' ? tier.price_usd_yearly : tier.price_usd_montly}
                    </span>
                    <span className="mobile-period">
                      {billingCycle === 'yearly' ? '/año' : '/mes'}
                    </span>
                  </div>
                  <p className="mobile-credits">{tier.monthly_credits} créditos mensuales</p>
                </div>

                <button 
                  className="mobile-subscribe-button"
                  onClick={() => handleSubscribe(tier)}
                >
                  {tier.name === 'Free' ? 'Plan Actual' : 'Obtener'}
                </button>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default MobilePricingPlansModal; 