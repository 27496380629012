import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../config/supabaseConfig';
import { useAuth } from '../context/AuthContext';
import { ChevronLeft, LogOut } from 'react-feather';
import './MobileProfile.css';

const MobileProfile = () => {
  const { user, auth } = useAuth();
  const [transactions, setTransactions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      fetchTransactions();
    }
  }, [user]);

  const fetchTransactions = async () => {
    try {
      const { data, error } = await supabase
        .from('credit_transactions')
        .select('*')
        .eq('user_id', user.id)
        .order('created_at', { ascending: false });

      if (error) throw error;
      setTransactions(data);
    } catch (error) {
      console.error('Error fetching transactions:', error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });
  };

  const handleSignOut = async () => {
    try {
      await supabase.auth.signOut();
      navigate('/auth');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <div className="mobile-profile">
      <div className="mobile-profile-header">
        <button 
          onClick={() => navigate('/main')}
          className="mobile-back-button"
        >
          <ChevronLeft size={24} />
        </button>
        <div className="mobile-logo">Grazia</div>
        <button 
          onClick={handleSignOut}
          className="mobile-sign-out-button"
          aria-label="Sign out"
        >
          <span>Sign out</span>
          <LogOut size={16} />
        </button>
      </div>

      <div className="mobile-profile-content">
        <div className="mobile-user-email">{user?.email}</div>
        <h1>My profile</h1>

        <div className="mobile-transaction-section">
          <h2>Transaction History</h2>
          <div className="mobile-transaction-list">
            <div className="mobile-transaction-header">
              <span>Date</span>
              <span>Operation</span>
              <span>Amount</span>
              <span>Description</span>
            </div>
            {transactions.map((transaction, index) => (
              <div key={index} className="mobile-transaction-item">
                <span>{formatDate(transaction.created_at)}</span>
                <span>{transaction.operation_type}</span>
                <span className={transaction.amount < 0 ? 'negative' : 'positive'}>
                  {transaction.amount}
                </span>
                <span>{transaction.description}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileProfile; 