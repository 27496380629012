import React, { useState, useEffect } from 'react';
import { supabase } from '../config/supabaseConfig';
import { useAuth } from '../context/AuthContext';
import './TransactionHistory.css';

const TransactionHistory = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();

  useEffect(() => {
    fetchTransactions();
  }, [user?.id]);

  const fetchTransactions = async () => {
    try {
      console.log('Fetching transactions for user:', user?.id);

      const { data, error } = await supabase
        .from('credit_transactions')
        .select('*')
        .eq('user_id', user.id)
        .order('created_at', { ascending: false });

      console.log('Transactions response:', { data, error });

      if (error) throw error;
      setTransactions(data || []);
    } catch (error) {
      console.error('Error fetching transactions:', error);
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const formatAmount = (amount) => {
    return amount.toFixed(2);
  };

  const getOperationTypeDisplay = (type) => {
    switch (type) {
      case 'IMAGE_GENERATION':
        return 'Image Generation';
      case 'MODEL_TRAINING':
        return 'Model Training';
      default:
        return type;
    }
  };

  if (loading) {
    return (
      <div className="transaction-history-container">
        <div className="loading">Loading transactions...</div>
      </div>
    );
  }

  return (
    <div className="transaction-history-container">
      <h2>Transaction History</h2>
      
      {transactions.length === 0 ? (
        <div className="no-transactions">
          No transactions found
        </div>
      ) : (
        <div className="transactions-table">
          <div className="table-header">
            <div className="header-cell">Date</div>
            <div className="header-cell">Operation</div>
            <div className="header-cell">Amount</div>
            <div className="header-cell">Description</div>
          </div>
          
          <div className="table-body">
            {transactions.map((transaction) => (
              <div key={transaction.id} className="table-row">
                <div className="cell date">
                  {formatDate(transaction.created_at)}
                </div>
                <div className="cell operation">
                  {getOperationTypeDisplay(transaction.operation_type)}
                </div>
                <div className={`cell amount ${transaction.amount < 0 ? 'negative' : 'positive'}`}>
                  {transaction.amount < 0 ? '' : '+'}
                  {formatAmount(transaction.amount)}
                </div>
                <div className="cell description">
                  {transaction.description}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default TransactionHistory; 