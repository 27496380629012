import React, { useState } from 'react';
import { ArrowRight, X } from 'react-feather';
import PricingModal from './PricingModal';
import './InsufficientCreditsModal.css';

const InsufficientCreditsModal = ({ onClose, onUpgrade, requiredCredits, currentBalance }) => {
  const [showPricingModal, setShowPricingModal] = useState(false);

  const handleSubscribeClick = () => {
    setShowPricingModal(true);
  };

  if (showPricingModal) {
    return <PricingModal onClose={() => setShowPricingModal(false)} />;
  }

  return (
    <div className="insufficient-credits-overlay">
      <div className="insufficient-credits-modal">
        <button 
          className="modal-close-button"
          onClick={onClose}
        >
          <X size={24} />
        </button>

        <div className="modal-content">
          <div className="modal-header">
            <h1>Having Fun? 🎉</h1>
            <p>
              Se te acabaron los créditos, Obtén mas para realizar esta acción
            </p>
          </div>

          <button 
            className="subscribe-button"
            onClick={handleSubscribeClick}
          >
            <span>SUSCRIBE HERE</span>
            <ArrowRight size={24} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default InsufficientCreditsModal; 