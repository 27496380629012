import { supabase } from '../config/supabaseConfig';
import * as fal from '@fal-ai/serverless-client';
import JSZip from 'jszip';

const uploadAllImagesToSupabase = async (uploadedImages) => {
  try {
    const uploadPromises = uploadedImages.map(async (image) => {
      const url = await uploadToSupabase(image.file);
      return url;
    });

    return await Promise.all(uploadPromises);
  } catch (error) {
    console.error('Error uploading images:', error);
    throw new Error('Failed to upload all images');
  }
};

const uploadToSupabase = async (file) => {
  try {
    const fileExt = file.name.split('.').pop();
    const fileName = `${Date.now()}-${Math.random().toString(36).substring(2)}.${fileExt}`;
    const filePath = `products/${fileName}`;

    const maxSize = 10 * 1024 * 1024;
    if (file.size > maxSize) {
      throw new Error(`File size must be less than 10MB`);
    }

    const { error } = await supabase.storage
      .from('product-images')
      .upload(filePath, file, {
        cacheControl: '3600',
        upsert: false,
        contentType: file.type,
        maxSize: maxSize
      });

    if (error) throw error;

    const { data: { publicUrl } } = supabase.storage
      .from('product-images')
      .getPublicUrl(filePath);

    return publicUrl;
  } catch (error) {
    console.error('Error in uploadToSupabase:', error);
    throw new Error(`Upload failed: ${error.message}`);
  }
};

export const trainModel = async ({ productName, uploadedImages, user, onInsufficientCredits }) => {
  try {
    if (uploadedImages.length < 5) {
      throw new Error('Please upload at least 5 images');
    }

    // Get the cost for model training
    const { data: priceData, error: priceError } = await supabase
      .from('credit_prices')
      .select('credit_cost')
      .eq('operation_type', 'MODEL_TRAINING')
      .single();

    if (priceError) throw priceError;

    // Get user's current balance
    const { data: userData, error: userError } = await supabase
      .from('users')
      .select('credits_balance')
      .eq('id', user.id)
      .single();

    if (userError) throw userError;

    const creditCost = Number(parseFloat(priceData.credit_cost).toFixed(2));
    const currentBalance = Number(parseFloat(userData.credits_balance).toFixed(2));

    // Check if user has enough credits
    if (currentBalance < creditCost) {
      if (onInsufficientCredits) {
        onInsufficientCredits(creditCost, currentBalance);
      }
      throw new Error('Insufficient credits');
    }

    // Create credit transaction
    const { error: transactionError } = await supabase
      .from('credit_transactions')
      .insert({
        user_id: user.id,
        operation_type: 'MODEL_TRAINING',
        amount: -creditCost,
        description: 'Credit deduction for model training',
        created_at: new Date().toISOString()
      });

    if (transactionError) throw transactionError;

    // Upload images to Supabase
    const imageUrls = await uploadAllImagesToSupabase(uploadedImages);

    // Configure fal client
    const apiKey = process.env.REACT_APP_FLUX_API_KEY;
    if (!apiKey) {
      throw new Error('API key not found');
    }

    fal.config({
      credentials: apiKey,
    });

    // Create zip and continue with FAL processing
    const zip = new JSZip();
    uploadedImages.forEach((image, index) => {
      zip.file(`image${index + 1}.jpg`, image.file);
    });
    const zipBlob = await zip.generateAsync({ type: "blob" });
    const zipFile = new File([zipBlob], "training_images.zip", { type: "application/zip" });

    const fileUrl = await fal.storage.upload(zipFile);

    const { request_id } = await fal.queue.submit("fal-ai/flux-lora-fast-training", {
      input: {
        images_data_url: fileUrl,
        trigger_word: productName,
        steps: 1000,
        create_masks: true,
      },
    });

    // Save to Supabase
    const { data: product, error: dbError } = await supabase
      .from('products')
      .insert([{
        name: productName,
        image_url: imageUrls[0],
        images_urls: imageUrls,
        user_id: user.id,
        request_id: request_id,
        training_status: 'processing'
      }])
      .select()
      .single();

    if (dbError) throw dbError;

    return product;

  } catch (error) {
    console.error('Error in trainModel:', error);
    throw error;
  }
}; 