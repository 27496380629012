import React from 'react';

const ErrorOverlay = ({ message }) => {
  return (
    <div 
      style={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        backgroundColor: '#ff4444',
        color: 'white',
        padding: '12px 20px',
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        maxWidth: '300px',
        minHeight: '48px',
        boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
        zIndex: 9999,
        fontFamily: 'Inter Display',
        fontSize: '14px'
      }}
    >
      <svg 
        width="20" 
        height="20" 
        viewBox="0 0 20 20" 
        fill="none" 
        stroke="currentColor"
      >
        <path 
          d="M10 18a8 8 0 100-16 8 8 0 000 16zM10 6v4m0 2h.01" 
          strokeWidth="2" 
          strokeLinecap="round"
        />
      </svg>
      {message}
    </div>
  );
};

export default ErrorOverlay; 