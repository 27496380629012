import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { supabase } from '../config/supabaseConfig';
import { useNavigate } from 'react-router-dom';
import PricingModal from './PricingModal';

// Define handleUpgradeClick as a separate function
const handleUpgradeClick = async (user) => {
  // This function is kept for backward compatibility but won't be used directly
  try {
    if (!user?.email || !user?.id) {
      console.error('Missing user data:', { user });
      throw new Error('Missing user information');
    }

    const mainPageUrl = `${window.location.origin}/main`;

    const requestData = {
      userId: user.id.toString(),
      userEmail: user.email.toString().toLowerCase().trim(),
      priceId: process.env.REACT_APP_STRIPE_STANDARD_PRICE_ID,
      returnUrl: mainPageUrl
    };

    const response = await fetch(
      `${process.env.REACT_APP_SUPABASE_URL}/functions/v1/create-checkout-session`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_SUPABASE_ANON_KEY}`,
        },
        body: JSON.stringify(requestData),
      }
    );

    const responseText = await response.text();

    if (!response.ok) {
      let errorData;
      try {
        errorData = JSON.parse(responseText);
      } catch (e) {
        errorData = { error: responseText };
      }
      throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
    }
    
    const data = JSON.parse(responseText);
    
    if (data.url) {
      window.location.href = data.url;
    } else {
      throw new Error('No checkout URL received');
    }
    
  } catch (error) {
    console.error('Error starting checkout:', error);
    alert(`Failed to start checkout: ${error.message}`);
  }
};

const HeaderActions = () => {
  const { user } = useAuth();
  const [creditsBalance, setCreditsBalance] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showPricingModal, setShowPricingModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let channel;

    const fetchCreditsBalance = async () => {
      try {
        if (!user?.id) return;

        const { data, error } = await supabase
          .from('users')
          .select('credits_balance')
          .eq('id', user.id)
          .single();

        if (error) throw error;
        setCreditsBalance(data?.credits_balance ?? 0);

        channel = supabase
          .channel(`public:users:id=eq.${user.id}`)
          .on(
            'postgres_changes',
            {
              event: '*',
              schema: 'public',
              table: 'users',
              filter: `id=eq.${user.id}`
            },
            (payload) => {
              console.log('Credits update received:', payload);
              if (payload.new && typeof payload.new.credits_balance !== 'undefined') {
                setCreditsBalance(payload.new.credits_balance);
              }
            }
          )
          .subscribe();

      } catch (error) {
        console.error('Error fetching credits balance:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCreditsBalance();

    return () => {
      if (channel) {
        supabase.removeChannel(channel);
      }
    };
  }, [user?.id]);

  return (
    <>
      <div className="Group29">
        <div 
          className="Group19" 
          onClick={() => navigate('/profile')}
          style={{ cursor: 'pointer' }}
        >
          <div className="Profile">Profile</div>
          <div className="Group18">
            <div className="Ellipse5" style={{ background: '#FFC300' }}></div>
            <div className="N">{user?.email?.[0]?.toUpperCase() || 'U'}</div>
          </div>
        </div>
        <div className="Group20">
          <div className="Credits">
            {loading ? 'Loading...' : `${creditsBalance} Credits`}
          </div>
          <div 
            className="Group17" 
            onClick={() => user && setShowPricingModal(true)}
          >
            <div className="Rectangle40"></div>
            <div className="Upgrade">
              {user?.id && user?.email ? 'Upgrade' : 'Loading...'}
            </div>
          </div>
        </div>
      </div>
      {showPricingModal && (
        <PricingModal onClose={() => setShowPricingModal(false)} />
      )}
    </>
  );
};

// Export both the component and the function
export { HeaderActions, handleUpgradeClick };
export default HeaderActions; 