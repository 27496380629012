import { supabase } from '../config/supabaseConfig';
import * as fal from '@fal-ai/serverless-client';

export const generateImage = async ({ prompt, user, selectedProduct, guidanceImage, seed, inferenceSteps = 50, guidanceScale = 7.5, aspectRatio = "1:1", onInsufficientCredits }) => {
  try {
    // Get the cost for image generation
    const { data: priceData, error: priceError } = await supabase
      .from('credit_prices')
      .select('credit_cost')
      .eq('operation_type', 'IMAGE_GENERATION')
      .single();

    if (priceError) {
      if (priceError.code === 'PGRST116') {
        throw new Error('Image generation price not configured. Please contact support.');
      }
      throw priceError;
    }

    // Get user's current balance
    const { data: userData, error: userError } = await supabase
      .from('users')
      .select('credits_balance')
      .eq('id', user.id)
      .single();

    if (userError) throw userError;

    const creditCost = Number(parseFloat(priceData.credit_cost).toFixed(2));
    const currentBalance = Number(parseFloat(userData.credits_balance).toFixed(2));

    // Check if user has enough credits
    if (currentBalance < creditCost) {
      if (onInsufficientCredits) {
        onInsufficientCredits(creditCost, currentBalance);
      }
      throw new Error('Insufficient credits');
    }

    // Create credit transaction (trigger will handle balance update)
    const { error: transactionError } = await supabase
      .from('credit_transactions')
      .insert({
        user_id: user.id,
        operation_type: 'IMAGE_GENERATION',
        amount: -creditCost,
        description: 'Credit deduction for image generation',
        created_at: new Date().toISOString()
      });

    if (transactionError) {
      if (transactionError.message.includes('Insufficient credits')) {
        throw new Error('Insufficient credits for this operation');
      }
      throw transactionError;
    }

    // Rest of the image generation code...
    const apiKey = process.env.REACT_APP_FLUX_API_KEY;
    if (!apiKey) {
      throw new Error('API key not found');
    }

    fal.config({
      credentials: apiKey,
    });

    const generationParams = {
      seed: seed !== -1 ? parseInt(seed) : Math.floor(Math.random() * Number.MAX_SAFE_INTEGER),
      loras: [{
        path: selectedProduct.lora_file_url,
        scale: 1.0
      }],
      prompt: prompt,
      image_size: {
        width: 1024,
        height: 1024
      },
      num_images: 1,
      output_format: "jpeg",
      guidance_scale: guidanceScale,
      negative_prompt: "ugly, blurry, low quality, distorted",
      num_inference_steps: Math.min(inferenceSteps, 50),
      enable_safety_checker: true
    };

    console.log('Generation params:', generationParams);

    // Generate image
    const result = await fal.subscribe("fal-ai/flux-lora", {
      input: generationParams,
      pollInterval: 1000,
      logs: true,
      onQueueUpdate: (update) => {
        console.log('Queue update:', update);
      }
    });

    if (!result.images?.[0]) {
      throw new Error('No image generated');
    }

    // Save generated image with seed value
    const { error: dbError } = await supabase
      .from('generated_images')
      .insert({
        user_id: user.id,
        product_id: selectedProduct.id,
        image_url: result.images[0].url,
        prompt_description: prompt,
        created_at: new Date().toISOString(),
        seed: result.seed ? result.seed.toString() : null,  // Store the seed used
        guidance_scale: guidanceScale,
        inference_steps: inferenceSteps
      });

    if (dbError) throw dbError;

    return result.images[0].url;

  } catch (error) {
    console.error('Error in generateImage:', error);
    throw error;
  }
}; 