import React, { useEffect, useState } from 'react';

const Alert = ({ type, message, onClose }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
      if (onClose) onClose();
    }, 3000);

    return () => clearTimeout(timer);
  }, [onClose]);

  if (!isVisible) return null;

  return (
    <div 
      style={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        width: '300px',
        height: '48px',
        backgroundColor: type === 'error' ? '#FF4444' : '#4CAF50',
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        padding: '0 16px',
        gap: '10px',
        color: 'white',
        fontFamily: 'Inter Display',
        fontSize: '14px',
        lineHeight: '1.2',
        zIndex: 10000,
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
        transform: 'translateZ(0)',
        animation: 'slideIn 0.3s ease-out',
        pointerEvents: 'none'
      }}
    >
      <svg 
        width="20" 
        height="20" 
        viewBox="0 0 20 20" 
        fill="none" 
        style={{ flexShrink: 0 }}
      >
        <path 
          d="M10 18a8 8 0 100-16 8 8 0 000 16zM10 6v4m0 2h.01" 
          stroke="currentColor" 
          strokeWidth="2" 
          strokeLinecap="round"
        />
      </svg>
      <span style={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        flex: 1
      }}>
        {message}
      </span>
    </div>
  );
};

export default Alert; 