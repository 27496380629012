import { createContext, useContext, useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { supabase } from '../config/supabaseConfig';

const CreditsContext = createContext({});

export const useCredits = () => useContext(CreditsContext);

export const CreditsProvider = ({ children }) => {
  const { user } = useAuth();
  const [userCredits, setUserCredits] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchUserCredits = async () => {
    try {
      if (!user?.id) return;

      const { data, error } = await supabase
        .from('users')
        .select('credits_balance, subscription_id')
        .eq('id', user.id)
        .single();

      if (error) throw error;
      setUserCredits(data);
    } catch (error) {
      console.error('Error fetching user credits:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      fetchUserCredits();

      // Set up real-time subscription
      const channel = supabase
        .channel(`public:users:id=eq.${user.id}`)
        .on(
          'postgres_changes',
          {
            event: '*',
            schema: 'public',
            table: 'users',
            filter: `id=eq.${user.id}`
          },
          (payload) => {
            console.log('Credits update received:', payload);
            if (payload.new && payload.new.credits_balance !== undefined) {
              setUserCredits(prev => ({
                ...prev,
                credits_balance: payload.new.credits_balance
              }));
            }
          }
        )
        .subscribe((status) => {
          console.log('Subscription status:', status);
        });

      // Cleanup subscription
      return () => {
        supabase.removeChannel(channel);
      };
    }
  }, [user]);

  // Refresh credits after actions
  const refreshCredits = () => {
    fetchUserCredits();
  };

  return (
    <CreditsContext.Provider value={{
      credits: userCredits?.credits_balance ?? 0,
      subscriptionId: userCredits?.subscription_id,
      loading,
      refreshCredits
    }}>
      {children}
    </CreditsContext.Provider>
  );
}; 