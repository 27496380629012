import React from 'react';
import { useNavigate } from 'react-router-dom';
import { X } from 'react-feather';

const AuthModal = ({ onClose }) => {
  const navigate = useNavigate();

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'rgba(0, 0, 0, 0.8)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1000,
      backdropFilter: 'blur(8px)'
    }}>
      <div style={{
        background: '#1A1A1A',
        borderRadius: '25px',
        padding: '40px',
        width: '90%',
        maxWidth: '500px',
        position: 'relative',
        border: '1px solid rgba(255, 255, 255, 0.1)'
      }}>
        <button
          onClick={onClose}
          style={{
            position: 'absolute',
            top: '20px',
            right: '20px',
            background: 'none',
            border: 'none',
            cursor: 'pointer',
            padding: '8px'
          }}
        >
          <X size={24} color="white" />
        </button>

        <div style={{
          marginBottom: '24px',
          textAlign: 'center'
        }}>
          <h2 style={{
            color: 'white',
            fontSize: '24px',
            fontFamily: 'Inter Display',
            fontWeight: '700',
            marginBottom: '12px'
          }}>
            Sign in Required
          </h2>
          <p style={{
            color: 'rgba(255, 255, 255, 0.7)',
            fontSize: '16px',
            fontFamily: 'Inter Display',
            marginBottom: '32px'
          }}>
            Please sign in to generate images
          </p>
        </div>

        <div style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px'
        }}>
          <button
            onClick={() => navigate('/auth')}
            style={{
              background: '#FFC300',
              border: 'none',
              borderRadius: '14px',
              padding: '14px 24px',
              color: 'black',
              fontSize: '16px',
              fontWeight: '600',
              cursor: 'pointer',
              transition: 'background 0.2s ease',
              fontFamily: 'Inter Display'
            }}
            onMouseEnter={(e) => e.currentTarget.style.background = '#FFD033'}
            onMouseLeave={(e) => e.currentTarget.style.background = '#FFC300'}
          >
            Sign In
          </button>
        </div>
      </div>
    </div>
  );
};

export default AuthModal; 