import React from 'react';
import { useNavigate } from 'react-router-dom';

const PaymentCancelled = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-black text-white p-4">
      <h1 className="text-2xl mb-4">Payment Cancelled</h1>
      <p className="mb-4">Your payment was cancelled and you have not been charged.</p>
      <button 
        onClick={() => navigate('/')}
        className="bg-white text-black px-4 py-2 rounded"
      >
        Return to Dashboard
      </button>
    </div>
  );
};

export default PaymentCancelled; 