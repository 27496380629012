import React, { useEffect, useState, useRef } from "react";
import { supabase } from '../config/supabaseConfig';
import "./MainPage.css";
import { Upload, Download, PlusCircle, ChevronDown, Loader, X, Image } from 'react-feather';
import SelectProductModal from './SelectProductModal';
import { useAuth } from '../context/AuthContext';
import LoadingProgress from './LoadingProgress';
import { useCredits } from '../context/CreditsContext';
import HeaderActions, { handleUpgradeClick } from './HeaderActions';
import * as fal from '@fal-ai/serverless-client';
import { v4 as uuidv4 } from 'uuid';
import ReactPlayer from 'react-player';
import InsufficientCreditsModal from './InsufficientCreditsModal';
import Alert from './Alert';
import ErrorOverlay from './ErrorOverlay';
import { useNavigate } from 'react-router-dom';
import AuthModal from './AuthModal';

const MAX_INFERENCE_STEPS = 50; // Maximum allowed by Flux LoRA API

const STORAGE_KEYS = {
  SELECTED_PRODUCT: 'grazia_selected_product'
};

const validateSeed = (seed) => {
  const MAX_BIGINT = "9223372036854775807";  // PostgreSQL bigint max value
  if (!seed) return null;
  const seedStr = seed.toString();
  return seedStr.length > 19 ? null : seed;
};

const MainPage = () => {
  const [galleryImages, setGalleryImages] = useState([]);
  const [showPricing, setShowPricing] = useState(false);
  const { user } = useAuth();
  const [productImages, setProductImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const fileInputRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const carouselRef = useRef(null);
  const [prompt, setPrompt] = useState('');
  const [selectedImageSize, setSelectedImageSize] = useState('square');
  const [guidanceScale, setGuidanceScale] = useState(3.5);
  const [inferenceSteps, setInferenceSteps] = useState(28);
  const [showGuidanceInfo, setShowGuidanceInfo] = useState(false);
  const [showInferenceInfo, setShowInferenceInfo] = useState(false);
  const { credits, loading: creditsLoading } = useCredits();
  const [showSelectProduct, setShowSelectProduct] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [generatedImage, setGeneratedImage] = useState(null);
  const [selectedRequestId, setSelectedRequestId] = useState('');
  const [generatedImages, setGeneratedImages] = useState([]);
  const [alert, setAlert] = useState(null);
  const [isDraggingContainer, setIsDraggingContainer] = useState(false);
  const [startDragX, setStartDragX] = useState(0);
  const [scrollLeftStart, setScrollLeftStart] = useState(0);
  const containerRef = useRef(null);
  const [userGeneratedImages, setUserGeneratedImages] = useState([]);
  const [expandedImage, setExpandedImage] = useState(null);
  const [introVideoUrl, setIntroVideoUrl] = useState('');
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [showInsufficientCreditsModal, setShowInsufficientCreditsModal] = useState(false);
  const [requiredCredits, setRequiredCredits] = useState(0);
  const [currentBalance, setCurrentBalance] = useState(0);
  const [prePrompt, setPrePrompt] = useState('');
  const navigate = useNavigate();
  const [showAuthModal, setShowAuthModal] = useState(false);
  const imageSizeOptions = [
    { value: 'vertical', label: 'Vertical (9:16)', width: 576, height: 1024 },
    { value: 'horizontal', label: 'Horizontal (16:9)', width: 1024, height: 576 },
    { value: 'square', label: 'Square (1:1)', width: 1024, height: 1024 },
    { value: 'portrait', label: 'Portrait (4:5)', width: 819, height: 1024 }
  ];
  const [showApiDetails, setShowApiDetails] = useState(false);
  const [currentApiDetails, setCurrentApiDetails] = useState(null);
  const [seed, setSeed] = useState('');
  const [showSeedInfo, setShowSeedInfo] = useState(false);
  const [referenceImage, setReferenceImage] = useState(null);

  useEffect(() => {
    fetchGalleryImages();
    fetchIntroVideo();
    fetchPrePrompt();
    
    // Load saved product from localStorage
    const loadSavedProduct = async () => {
      const savedProductId = localStorage.getItem(STORAGE_KEYS.SELECTED_PRODUCT);
      if (savedProductId && user) {
        try {
          const { data: product, error } = await supabase
            .from('products')
            .select('*')
            .eq('id', savedProductId)
            .single();

          if (error) throw error;
          if (product) {
            setSelectedProduct(product);
            setSelectedRequestId(product.request_id);
          }
        } catch (error) {
          console.error('Error loading saved product:', error);
          localStorage.removeItem(STORAGE_KEYS.SELECTED_PRODUCT);
        }
      }
    };

    loadSavedProduct();

    // Set up real-time subscription for both video and pre-prompt
    const channel = supabase
      .channel('app_variables_changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'app_variables'
        },
        (payload) => {
          if (payload.new?.title === 'video_intro' && payload.new?.video_url) {
            setIntroVideoUrl(payload.new.video_url);
          }
          if (payload.new?.title === 'pre_prompt' && payload.new?.text_content_1) {
            setPrePrompt(payload.new.text_content_1);
          }
        }
      )
      .subscribe();

    // Cleanup subscription
    return () => {
      supabase.removeChannel(channel);
    };
  }, [user]);

  const fetchPrePrompt = async () => {
    try {
      const { data, error } = await supabase
        .from('app_variables')
        .select('text_content_1')
        .eq('title', 'pre_prompt')
        .single();

      if (error) throw error;
      
      if (data?.text_content_1) {
        setPrePrompt(data.text_content_1);
      }
    } catch (error) {
      console.error('Error fetching pre-prompt:', error);
      showAlert('error', 'Error loading pre-prompt configuration');
    }
  };

  useEffect(() => {
    console.log('Current productImages:', productImages);
  }, [productImages]);

  useEffect(() => {
    if (user?.id) {
      fetchUserGeneratedImages();
    }
  }, [user]);

  const fetchGalleryImages = async () => {
    try {
      console.log('Fetching gallery images...');
      const { data, error } = await supabase
        .from('gallery_examples')
        .select('*');

      if (error) {
        console.error('Supabase error:', error);
        throw error;
      }
      
      console.log('Gallery data received:', {
        count: data?.length,
        firstImage: data?.[0],
        allData: data
      });

      setGalleryImages(data);
    } catch (error) {
      console.error('Error fetching gallery images:', error.message);
    }
  };

  const fetchUserGeneratedImages = async () => {
    try {
      const { data, error } = await supabase
        .from('generated_images')
        .select('*')
        .eq('user_id', user.id)
        .order('created_at', { ascending: false });

      if (error) throw error;

      setUserGeneratedImages(data);
      
      if (data && data.length > 0) {
        setGeneratedImage(data[0].image_url);
        setGeneratedImages(data.map(item => item.image_url));
      }
    } catch (error) {
      console.error('Error fetching generated images:', error);
      showAlert('error', 'Error loading your generated images');
    }
  };

  const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const handleImageUpload = async (e) => {
    const files = Array.from(e.target.files);
    
    console.log('Files selected:', files.length);
    
    if (files.length === 0) return;

    const maxSize = 10 * 1024 * 1024; // 10MB
    const invalidFiles = files.filter(file => {
      if (!file.type.startsWith('image/')) {
        console.log('Invalid file type:', file.type);
        return true;
      }
      if (file.size > maxSize) {
        console.log('File too large:', file.name, formatFileSize(file.size));
        return true;
      }
      return false;
    });

    if (invalidFiles.length > 0) return;

    setIsLoading(true);
    setUploadProgress(0);

    const processedImages = files.map(file => {
      const url = URL.createObjectURL(file);
      console.log('Created URL for file:', url);
      return {
        url,
        file
      };
    });

    console.log('Processed images:', processedImages);

    setProductImages(prev => {
      const newImages = [...prev, ...processedImages];
      console.log('Updated images array:', newImages);
      return newImages;
    });
    
    setIsLoading(false);
    setUploadProgress(0);
  };

  const goToNext = () => {
    setCurrentImageIndex((prev) => {
      if (prev >= galleryImages.length - 1) return 0;
      return prev + 1;
    });
  };

  const goToPrevious = () => {
    setCurrentImageIndex((prev) => {
      if (prev <= 0) return galleryImages.length - 1;
      return prev - 1;
    });
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
    setIsDragging(true);
    setStartX(e.pageX);
    if (carouselRef.current) {
      carouselRef.current.classList.add('dragging');
    }
  };

  const handleMouseMove = (e) => {
    if (!isDragging || !carouselRef.current) return;
    e.preventDefault();
    
    const x = e.pageX;
    const walk = x - startX;
    const threshold = 100;
    
    // Limit drag distance
    const limitedWalk = Math.max(Math.min(walk, threshold), -threshold);
    carouselRef.current.style.transform = `translateX(${limitedWalk}px)`;
  };

  const handleMouseUp = () => {
    if (!isDragging || !carouselRef.current) return;
    
    const walk = parseFloat(carouselRef.current.style.transform.replace('translateX(', ''));
    const threshold = 50;
    
    if (walk > threshold) {
      goToPrevious();
    } else if (walk < -threshold) {
      goToNext();
    }
    
    setIsDragging(false);
    carouselRef.current.classList.remove('dragging');
    carouselRef.current.style.transform = '';
  };

  const handleDotClick = (index) => {
    setCurrentImageIndex(index);
  };

  const calculatePositions = () => {
    const total = galleryImages.length;
    const prev = currentImageIndex === 0 ? total - 1 : currentImageIndex - 1;
    const next = currentImageIndex === total - 1 ? 0 : currentImageIndex + 1;
    return { prev, next };
  };

  const handleTouchStart = (e) => {
    setIsDragging(true);
    setStartX(e.touches[0].pageX);
  };

  const handleTouchMove = (e) => {
    if (!isDragging) return;
    const x = e.touches[0].pageX;
    const walk = x - startX;
    // Use same logic as mouse move
  };

  const handleTouchEnd = handleMouseUp;

  const handleCreateNewClick = () => {
    setShowSelectProduct(true);
  };

  const handlePromptChange = (e) => {
    setPrompt(e.target.value);
  };

  // Add this function to handle auto-resize
  const handleTextAreaHeight = (e) => {
    const textarea = e.target;
    textarea.style.height = 'auto';
    textarea.style.height = `${Math.min(textarea.scrollHeight, 120)}px`; // 120px max height
  };

  const handleProductSelect = (product) => {
    console.log('Product selected:', product);  // Debug log
    if (!product || !product.id) {
      showAlert('error', 'Invalid product selected');
      return;
    }
    setSelectedProduct(product);
    setSelectedRequestId(product.request_id);
    setShowSelectProduct(false);
    
    // Save to localStorage
    localStorage.setItem(STORAGE_KEYS.SELECTED_PRODUCT, product.id);
  };

  const showAlert = (type, message) => {
    setAlert({ type, message });
    setTimeout(() => setAlert(null), 3000);
  };

  const handleGenerateImage = async () => {
    if (!user) {
      setShowAuthModal(true);
      return;
    }

    if (!selectedProduct || !prompt.trim()) {
      showAlert('error', 'Please select a product and enter a prompt');
      return;
    }

    try {
      // Validate image size first
      const selectedSize = imageSizeOptions.find(option => option.value === selectedImageSize);
      if (!selectedSize) {
        throw new Error('Invalid image size selected');
      }

      // Get the cost for image generation
      const { data: priceData, error: priceError } = await supabase
        .from('credit_prices')
        .select('credit_cost')
        .eq('operation_type', 'IMAGE_GENERATION')
        .single();

      if (priceError) {
        if (priceError.code === 'PGRST116') {
          throw new Error('Image generation price not configured. Please contact support.');
        }
        throw priceError;
      }

      // Get user's current balance
      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('credits_balance')
        .eq('id', user.id)
        .single();

      if (userError) throw userError;

      const creditCost = Number(parseFloat(priceData.credit_cost).toFixed(2));
      const currentBalance = Number(parseFloat(userData.credits_balance).toFixed(2));

      // Check if user has enough credits
      if (currentBalance < creditCost) {
        setRequiredCredits(creditCost);
        setCurrentBalance(currentBalance);
        setShowInsufficientCreditsModal(true);
        return;
      }

      setIsGenerating(true);

      // Create credit transaction (trigger will handle balance update)
      const { error: transactionError } = await supabase
        .from('credit_transactions')
        .insert({
          user_id: user.id,
          operation_type: 'IMAGE_GENERATION',
          amount: -creditCost,
          description: 'Credit deduction for image generation',
          created_at: new Date().toISOString()
        });

      if (transactionError) {
        if (transactionError.message.includes('Insufficient credits')) {
          throw new Error('Insufficient credits for this operation');
        }
        throw transactionError;
      }

      // Configure fal client and continue with image generation...
      const apiKey = process.env.REACT_APP_FLUX_API_KEY;
      if (!apiKey) {
        throw new Error('API key not found');
      }

      fal.config({
        credentials: apiKey,
      });

      console.log('Starting generation process with:', {
        selectedProduct,
        prompt,
        inferenceSteps,
        guidanceScale,
        selectedImageSize
      });

      if (!selectedRequestId) {
        throw new Error('No request ID found for selected product');
      }

      // Get the training result using fal client
      console.log('Fetching training result for request:', selectedRequestId);
      const trainingResult = await fal.queue.result("fal-ai/flux-lora-fast-training", {
        requestId: selectedRequestId,
      });

      console.log('Training result:', trainingResult);

      if (!trainingResult?.diffusers_lora_file?.url) {
        throw new Error('No LoRA URL found in training result');
      }

      const loraUrl = trainingResult.diffusers_lora_file.url;
      console.log('Successfully retrieved LoRA URL:', loraUrl);

      // Validate and clamp inference steps
      const clampedInferenceSteps = Math.min(parseInt(inferenceSteps), MAX_INFERENCE_STEPS);
      
      if (parseInt(inferenceSteps) > MAX_INFERENCE_STEPS) {
        console.warn(`Inference steps clamped from ${inferenceSteps} to ${MAX_INFERENCE_STEPS}`);
      }

      // Prepare generation parameters
      const generationParams = {
        prompt: prompt,
        loras: [{
          path: loraUrl,
          scale: 1.0
        }],
        num_inference_steps: clampedInferenceSteps,
        guidance_scale: parseFloat(guidanceScale),
        image_size: {
          width: selectedSize.width,
          height: selectedSize.height
        },
        num_images: 1,
        enable_safety_checker: true,
        output_format: "jpeg",
        seed: seed ? parseInt(seed) : undefined
      };

      console.log('Sending generation request with parameters:', {
        ...generationParams,
        originalInferenceSteps: inferenceSteps,
        clampedInferenceSteps: clampedInferenceSteps
      });

      // Generate image using fal client with flux-lora endpoint
      const result = await fal.subscribe('fal-ai/flux-lora', {
        input: generationParams,
        pollInterval: 1000,
        logs: true,
        onQueueUpdate: (update) => {
          console.log('Queue update received:', update);
          if (update.status === "IN_PROGRESS") {
            console.log('Generation progress:', {
              status: update.status,
              requestId: update.request_id,
              logs: update.logs,
              metrics: update.metrics,
              input: update.input
            });
          }
        },
      });

      console.log('Generation result received:', result);

      if (!result || !result.images || !result.images.length) {
        throw new Error('Invalid result format received');
      }

      // Get the generated image URL and save it
      const generatedImageUrl = result.images[0].url;
      console.log('Generated image URL:', generatedImageUrl);

      // Download the generated image
      const imageResponse = await fetch(generatedImageUrl);
      if (!imageResponse.ok) throw new Error('Failed to download generated image');
      const imageBlob = await imageResponse.blob();

      // Generate a unique filename
      const fileName = `${uuidv4()}.jpg`;
      const filePath = `generated_content/${fileName}`;

      // Upload to Supabase Storage
      const { data: uploadData, error: uploadError } = await supabase.storage
        .from('generated_content')
        .upload(filePath, imageBlob, {
          contentType: 'image/jpeg',
          cacheControl: '3600'
        });

      if (uploadError) throw uploadError;

      // Get the public URL of the uploaded image
      const { data: { publicUrl } } = supabase.storage
        .from('generated_content')
        .getPublicUrl(filePath);

      // Database insert with validated dimensions
      const { data: dbData, error: dbError } = await supabase
        .from('generated_images')
        .insert({
          user_id: user.id,
          product_id: selectedProduct.id,
          image_url: publicUrl,
          prompt_description: prompt,
          created_at: new Date().toISOString(),
          guidance_scale: parseFloat(guidanceScale) || 7.5,
          inference_steps: clampedInferenceSteps || 50,
          width: selectedSize.width,
          height: selectedSize.height,
          seed: result.seed ? result.seed.toString() : null
        })
        .select()
        .single();

      if (dbError) throw dbError;

      // Update state with the stored image URL
      setGeneratedImage(publicUrl);
      setGeneratedImages(prev => [...prev, publicUrl]);

      await fetchUserGeneratedImages();
      showAlert('success', 'Image generated and saved successfully!');

    } catch (error) {
      console.error('Error in image generation process:', error);
      showAlert('error', `Error: ${error.message}`);
    } finally {
      setIsGenerating(false);
    }
  };

  const handleContainerMouseDown = (e) => {
    setIsDraggingContainer(true);
    setStartDragX(e.pageX - containerRef.current.offsetLeft);
    setScrollLeftStart(containerRef.current.scrollLeft);
  };

  const handleContainerMouseMove = (e) => {
    if (!isDraggingContainer) return;
    e.preventDefault();
    const x = e.pageX - containerRef.current.offsetLeft;
    const walk = (x - startDragX) * 2;
    containerRef.current.scrollLeft = scrollLeftStart - walk;
  };

  const handleContainerMouseUp = () => {
    setIsDraggingContainer(false);
  };

  const handleImageExpand = (imageUrl) => {
    setExpandedImage(imageUrl);
  };

  const handleImageCollapse = () => {
    setExpandedImage(null);
  };

  const handleImageDownload = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `generated-image-${Date.now()}.jpg`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading image:', error);
    }
  };

  const fetchIntroVideo = async () => {
    try {
      const { data, error } = await supabase
        .from('app_variables')
        .select('video_url')
        .eq('title', 'video_intro')
        .single();

      if (error) throw error;
      
      if (data?.video_url) {
        console.log('Fetched new video URL:', data.video_url);
        setIntroVideoUrl(data.video_url);
      }
    } catch (error) {
      console.error('Error fetching intro video:', error);
    }
  };

  const VideoModal = ({ videoUrl, onClose }) => (
    <div 
      onClick={(e) => {
        // Only close if clicking the outer container, not the video or controls
        if (e.target === e.currentTarget) {
          onClose();
        }
      }}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'rgba(0, 0, 0, 0.95)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1000,
        padding: '40px'
      }}
    >
      <div style={{
        position: 'relative',
        width: '90%',
        maxWidth: '1200px',
        aspectRatio: '16/9',
        background: 'black',
        borderRadius: '25px',
        overflow: 'hidden'
      }}>
        <ReactPlayer
          url={videoUrl}
          width="100%"
          height="100%"
          playing={true}
          controls={true}
          muted={false}
          playsinline={true}
          config={{
            youtube: {
              playerVars: {
                showinfo: 1,
                modestbranding: 1,
                rel: 0,
                autoplay: 1
              }
            }
          }}
        />
        <button
          onClick={onClose}
          style={{
            position: 'absolute',
            top: '20px',
            right: '20px',
            background: 'white',
            border: 'none',
            borderRadius: '50%',
            width: '40px',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            zIndex: 2
          }}
        >
          <X size={24} color="black" />
        </button>
      </div>
    </div>
  );

  const handleDeleteImage = async (imageUrl) => {
    try {
      // Extract the file path from the URL
      const storageUrl = new URL(imageUrl);
      const filePath = storageUrl.pathname.split('/public/')[1];

      // First delete the image from storage
      const { error: storageError } = await supabase.storage
        .from('generated_content')
        .remove([filePath]);

      if (storageError) throw storageError;

      // Then delete the record from the generated_images table
      const { error: dbError } = await supabase
        .from('generated_images')
        .delete()
        .eq('image_url', imageUrl);

      if (dbError) throw dbError;

      // Update local state
      setUserGeneratedImages(prev => prev.filter(img => img.image_url !== imageUrl));
      setGeneratedImages(prev => prev.filter(url => url !== imageUrl));
      
      if (generatedImage === imageUrl) {
        setGeneratedImage(null);
      }

      showAlert('success', 'Image deleted successfully');
    } catch (error) {
      console.error('Error deleting image:', error);
      showAlert('error', 'Failed to delete image');
    }
  };

  const handleGenerateImageClick = () => {
    if (!user) {
      setShowAuthModal(true);
      return;
    }
    handleGenerateImage();
  };

  const handleGuidanceScaleChange = (e) => {
    const value = parseFloat(e.target.value);
    if (value >= 1 && value <= 20) {
      setGuidanceScale(value);
    }
  };

  const handleInferenceStepsChange = (e) => {
    const value = parseInt(e.target.value);
    if (!isNaN(value)) {
      if (value > MAX_INFERENCE_STEPS) {
        showAlert('warning', `Maximum inference steps is ${MAX_INFERENCE_STEPS}. Value will be clamped when generating.`);
      }
      setInferenceSteps(value);
    } else if (e.target.value === '') {
      setInferenceSteps('');
    }
  };

  const handleSeedChange = (e) => {
    const value = e.target.value;
    if (value === '' || /^\d+$/.test(value)) {
      setSeed(value);
    }
  };

  // Add this handler function
  const handleGenerateMore = (image) => {
    const imageData = userGeneratedImages.find(img => img.image_url === image.image_url);
    if (imageData) {
      setSeed(imageData.seed);
      setGuidanceScale(imageData.guidance_scale);
      setInferenceSteps(imageData.inference_steps);
      setSelectedImageSize(
        imageSizeOptions.find(
          option => option.width === imageData.width && option.height === imageData.height
        )?.value || 'horizontal'
      );
      setReferenceImage(imageData.image_url);
    }
  };

  const Footer = () => {
    const currentYear = new Date().getFullYear();
    
    return (
      <footer className="desktop-footer">
        <div className="desktop-footer-content">
          <div className="desktop-footer-grid">
            <div className="desktop-footer-section">
              <h4>Signotech LLC</h4>
              <p>Transformando el futuro del diseño con inteligencia artificial</p>
            </div>
            
            <div className="desktop-footer-section">
              <h4>Contacto</h4>
              <a href="mailto:contact@grazia.ai">contact@grazia.ai</a>
              <a href="tel:+12819359448">+1 281-935-9448</a>
              <p>Delaware, United States</p>
            </div>
            
            <div className="desktop-footer-section">
              <h4>Legal</h4>
              <a href="/terms">Términos y Condiciones</a>
              <a href="/privacy">Política de Privacidad</a>
              <a href="/cookies">Política de Cookies</a>
            </div>
          </div>
          
          <div className="desktop-footer-bottom">
            <p>&copy; {currentYear} Signotech LLC. Todos los derechos reservados.</p>
          </div>
        </div>
      </footer>
    );
  };

  return (
    <div className="page-container">
      <div className="left-panel-container">
        <div className="left-panel">
          <div className="Grazia">Grazia</div>
          
          <div className="Frame46">
            <div className="Frame43" 
              style={{
                alignSelf: 'stretch',
                height: '200px',
                padding: 0,
                background: 'black',
                borderRadius: '25px',
                overflow: 'hidden',
                border: '1px solid rgba(255, 255, 255, 0.30)',
                position: 'relative'
              }}
            >
              {introVideoUrl && (
                <div style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  overflow: 'hidden'
                }}>
                  <ReactPlayer
                    url={introVideoUrl}
                    width="100%"
                    height="100%"
                    playing={true}
                    loop={true}
                    muted={true}
                    playsinline={true}
                    config={{
                      youtube: {
                        playerVars: {
                          showinfo: 0,
                          controls: 0,
                          modestbranding: 1,
                          rel: 0,
                          autoplay: 1
                        }
                      }
                    }}
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      width: '100%',
                      height: '250%'
                    }}
                  />
                  {/* Clickable overlay */}
                  <div 
                    onClick={() => setShowVideoModal(true)}
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      background: 'rgba(0, 0, 0, 0.2)',
                      cursor: 'pointer',
                      transition: 'background 0.2s ease',
                      zIndex: 2,
                      display: 'flex',
                      alignItems: 'flex-end',
                      padding: '20px'
                    }}
                    onMouseEnter={(e) => e.currentTarget.style.background = 'rgba(0, 0, 0, 0.4)'}
                    onMouseLeave={(e) => e.currentTarget.style.background = 'rgba(0, 0, 0, 0.2)'}
                  >
                    <div style={{
                      color: 'white',
                      fontSize: '25.96px',
                      fontFamily: 'Inter Display',
                      fontWeight: '700'
                    }}>
                      Comienza aquí...
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="Frame42" style={{
              alignSelf: 'stretch',
              width: '357px',
              padding: '25px',
              background: 'black',
              borderRadius: '25px',
              overflow: 'hidden',
              border: '1px solid rgba(255, 255, 255, 0.30)',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '10px',
              boxSizing: 'border-box'
            }}>
              <div className="Frame32" style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: '16px'
              }}>
                <div className="Frame28" style={{
                  alignSelf: 'stretch',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  gap: '15px'
                }}>
                  <div className="Frame26" style={{
                    alignSelf: 'stretch',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: '5px'
                  }}>
                    <div className="ProductStudio" style={{
                      alignSelf: 'stretch',
                      color: 'white',
                      fontSize: '25.96px',
                      fontFamily: 'Inter Display',
                      fontWeight: '700',
                      wordWrap: 'break-word',
                      lineHeight: '1.2'
                    }}>
                      Product Studio
                    </div>
                    <div className="EntrenaNuestroModeloAiConImGenesDeTuProducto" style={{
                      alignSelf: 'stretch',
                      color: 'rgba(255, 255, 255, 0.5)',
                      fontSize: '14px',
                      fontFamily: 'Inter Display',
                      fontWeight: '400',
                      wordWrap: 'break-word',
                      lineHeight: '1.4'
                    }}>
                      Entrena nuestro modelo AI con imágenes de tu producto
                    </div>
                  </div>
                </div>
                <div 
                  className="Frame28" 
                  onClick={handleCreateNewClick}
                  style={{
                    alignSelf: 'stretch',
                    display: 'inline-flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    gap: '10px',
                    cursor: 'pointer'
                  }}
                >
                  <div className="CrearNuevo" style={{
                    color: '#FFC300',
                    fontSize: '18px',
                    fontFamily: 'Inter Display',
                    fontWeight: '700',
                    letterSpacing: '0.36px',
                    overflowWrap: 'break-word'
                  }}>
                    CREAR PRODUCTO
                  </div>
                  <PlusCircle size={20} color="#FFC300" strokeWidth={2} />
                </div>
              </div>
            </div>
            <div className="Frame44">
              <div className="Frame36">
                <div className="Frame35" style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '8px'
                }}>
                  <div className="GeneraImGenes" style={{
                    fontSize: '25.96px',
                    fontFamily: 'Inter Display',
                    fontWeight: '700',
                    color: 'white'
                  }}>
                    Genera Imágenes
                  </div>
                  <div className="LasImGenesSeCrearanUsandoElProductoQueTengasSeleccionadoArriba" style={{
                    color: 'rgba(255, 255, 255, 0.5)',
                    fontSize: '14px'
                  }}>
                    Las imágenes se crearan usando el producto que tengas seleccionado arriba
                  </div>
                </div>
                
                {selectedProduct && (
                  <>
                    <div 
                      className="Frame88" 
                      style={{
                        width: '300px',
                        height: '100px',
                        padding: '3px',
                        background: 'black',
                        borderRadius: '14px',
                        border: '1px solid white',
                        display: 'flex',
                        marginTop: '24px',
                        cursor: 'pointer',
                        transition: 'transform 0.2s ease, border-color 0.2s ease',
                      }}
                      onClick={() => setShowSelectProduct(true)}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.transform = 'scale(1.02)';
                        e.currentTarget.style.borderColor = '#FFC300';
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.transform = 'scale(1)';
                        e.currentTarget.style.borderColor = 'white';
                      }}
                    >
                      <div className="Frame87" style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '15px',
                        padding: '0 10px 0 0'
                      }}>
                        <img 
                          className="Rectangle80" 
                          style={{
                            width: '94px',
                            height: '94px',
                            borderRadius: '13.16px',
                            objectFit: 'cover',
                            flexShrink: 0
                          }} 
                          src={selectedProduct.image_url} 
                          alt={selectedProduct.name}
                        />
                        <div style={{
                          color: 'white',
                          fontSize: '18px',
                          fontFamily: 'Inter Display',
                          fontWeight: '400',
                          lineHeight: '21.06px',
                          flex: 1
                        }}>
                          {selectedProduct.name}
                        </div>
                      </div>
                    </div>

                    {referenceImage && (
                      <div className="Frame88" style={{
                        width: '300px',
                        height: '100px',
                        padding: '3px',
                        background: 'black',
                        borderRadius: '14px',
                        border: '1px solid rgba(255, 255, 255, 0.3)',
                        display: 'flex',
                        marginTop: '12px'
                      }}>
                        <div className="Frame87" style={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          gap: '15px',
                          padding: '0 10px 0 0'
                        }}>
                          <img 
                            className="Rectangle80" 
                            style={{
                              width: '94px',
                              height: '94px',
                              borderRadius: '13.16px',
                              objectFit: 'cover',
                              flexShrink: 0
                            }} 
                            src={referenceImage} 
                            alt="Reference image"
                          />
                          <div style={{
                            color: 'rgba(255, 255, 255, 0.7)',
                            fontSize: '18px',
                            fontFamily: 'Inter Display',
                            fontWeight: '400',
                            lineHeight: '21.06px',
                            flex: 1
                          }}>
                            Imagen de guía
                          </div>
                          <button
                            onClick={() => setReferenceImage(null)}
                            style={{
                              background: 'none',
                              border: 'none',
                              padding: '8px',
                              cursor: 'pointer',
                              opacity: 0.7,
                              transition: 'opacity 0.2s ease'
                            }}
                            onMouseEnter={(e) => e.currentTarget.style.opacity = 1}
                            onMouseLeave={(e) => e.currentTarget.style.opacity = 0.7}
                          >
                            <svg 
                              xmlns="http://www.w3.org/2000/svg" 
                              width="14" 
                              height="14" 
                              viewBox="0 0 24 24" 
                              fill="none" 
                              stroke="white" 
                              strokeWidth="2" 
                              strokeLinecap="round" 
                              strokeLinejoin="round"
                            >
                              <line x1="18" y1="6" x2="6" y2="18"></line>
                              <line x1="6" y1="6" x2="18" y2="18"></line>
                            </svg>
                          </button>
                        </div>
                      </div>
                    )}
                  </>
                )}

                <div className="Frame34" style={{
                  marginTop: '24px',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '24px',
                  width: '300px'
                }}>
                  <div className="DescribeTuImagen" style={{
                    color: 'white',
                    fontSize: '18px',
                    fontFamily: 'Inter Display',
                    fontWeight: '500',
                    lineHeight: '1'
                  }}>
                    Describe tu imagen
                  </div>
                  <textarea
                    value={prompt}
                    onChange={(e) => {
                      setPrompt(e.target.value);
                      handleTextAreaHeight(e);
                    }}
                    onInput={handleTextAreaHeight}
                    placeholder="Iluminacion volumetrica, hiper realismo, color grade dramatico"
                    rows={1}
                    style={{
                      width: '284px',
                      minHeight: '40px',
                      padding: '8px',
                      resize: 'none',
                      lineHeight: '1.2',
                      border: '1px solid rgba(255, 255, 255, 0.1)',
                      borderRadius: '8px',
                      background: 'rgba(255, 255, 255, 0.05)',
                      color: 'white'
                    }}
                  />
                  
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px'
                  }}>
                    <div style={{
                      color: 'white',
                      fontSize: '18px',
                      fontFamily: 'Inter Display',
                      fontWeight: '500',
                      lineHeight: '1'
                    }}>
                      Aspect Ratio
                    </div>
                    <select
                      value={selectedImageSize}
                      onChange={(e) => setSelectedImageSize(e.target.value)}
                      style={{
                        width: '284px',
                        padding: '8px',
                        border: '1px solid rgba(255, 255, 255, 0.1)',
                        borderRadius: '8px',
                        background: 'rgba(255, 255, 255, 0.05)',
                        color: 'white',
                        fontSize: '16px',
                        fontFamily: 'Inter Display',
                        cursor: 'pointer'
                      }}
                    >
                      {imageSizeOptions.map((option) => (
                        <option 
                          key={option.value} 
                          value={option.value}
                          style={{
                            background: '#1a1a1a',
                            color: 'white'
                          }}
                        >
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px'
                  }}>
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px'
                    }}>
                      <div style={{
                        color: 'white',
                        fontSize: '18px',
                        fontFamily: 'Inter Display',
                        fontWeight: '500',
                        lineHeight: '1'
                      }}>
                        Guidance Scale
                      </div>
                      <div 
                        onClick={() => setShowGuidanceInfo(!showGuidanceInfo)}
                        style={{
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: '20px',
                          height: '20px',
                          borderRadius: '50%',
                          border: '1px solid rgba(255, 255, 255, 0.5)',
                          color: 'rgba(255, 255, 255, 0.5)',
                          fontSize: '14px'
                        }}
                      >
                        ?
                      </div>
                    </div>
                    {showGuidanceInfo && (
                      <div style={{
                        background: 'rgba(0, 0, 0, 0.8)',
                        padding: '12px',
                        borderRadius: '8px',
                        color: 'white',
                        fontSize: '14px',
                        lineHeight: '1.4',
                        marginBottom: '8px'
                      }}>
                        Controls how closely the generated image follows the prompt. Higher values (7-20) follow the prompt more strictly, while lower values (1-5) allow more creative freedom. Recommended: 7.5 for product photography.
                      </div>
                    )}
                    <input
                      type="text"
                      value={guidanceScale}
                      onChange={(e) => {
                        const value = parseFloat(e.target.value);
                        if (!isNaN(value) && value >= 1 && value <= 20) {
                          setGuidanceScale(value);
                        } else if (e.target.value === '') {
                          setGuidanceScale('');
                        }
                      }}
                      onBlur={() => {
                        if (guidanceScale === '') {
                          setGuidanceScale(7.5);
                        }
                      }}
                      style={{
                        width: '284px',
                        padding: '8px',
                        border: '1px solid rgba(255, 255, 255, 0.1)',
                        borderRadius: '8px',
                        background: 'rgba(255, 255, 255, 0.05)',
                        color: 'white',
                        fontSize: '16px',
                        fontFamily: 'Inter Display',
                        WebkitAppearance: 'none',
                        MozAppearance: 'textfield',
                        appearance: 'none'
                      }}
                    />
                  </div>

                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px'
                  }}>
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px'
                    }}>
                      <div style={{
                        color: 'white',
                        fontSize: '18px',
                        fontFamily: 'Inter Display',
                        fontWeight: '500',
                        lineHeight: '1'
                      }}>
                        Inference Steps
                      </div>
                      <div 
                        onClick={() => setShowInferenceInfo(!showInferenceInfo)}
                        style={{
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: '20px',
                          height: '20px',
                          borderRadius: '50%',
                          border: '1px solid rgba(255, 255, 255, 0.5)',
                          color: 'rgba(255, 255, 255, 0.5)',
                          fontSize: '14px'
                        }}
                      >
                        ?
                      </div>
                    </div>
                    {showInferenceInfo && (
                      <div style={{
                        background: 'rgba(0, 0, 0, 0.8)',
                        padding: '12px',
                        borderRadius: '8px',
                        color: 'white',
                        fontSize: '14px',
                        lineHeight: '1.4',
                        marginBottom: '8px'
                      }}>
                        Controls the number of denoising steps. Higher values (20-50) produce better quality but take longer, while lower values (20-30) are faster but may reduce quality. Maximum allowed value is 50. Recommended: 28 for a good balance.
                      </div>
                    )}
                    <input
                      type="text"
                      value={inferenceSteps}
                      onChange={handleInferenceStepsChange}
                      onBlur={() => {
                        if (inferenceSteps === '' || inferenceSteps < 1) {
                          setInferenceSteps(28);
                        } else if (inferenceSteps > MAX_INFERENCE_STEPS) {
                          setInferenceSteps(MAX_INFERENCE_STEPS);
                        }
                      }}
                      style={{
                        width: '284px',
                        padding: '8px',
                        border: '1px solid rgba(255, 255, 255, 0.1)',
                        borderRadius: '8px',
                        background: 'rgba(255, 255, 255, 0.05)',
                        color: 'white',
                        fontSize: '16px',
                        fontFamily: 'Inter Display',
                        WebkitAppearance: 'none',
                        MozAppearance: 'textfield',
                        appearance: 'none'
                      }}
                    />
                  </div>

                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px'
                  }}>
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px'
                    }}>
                      <div style={{
                        color: 'white',
                        fontSize: '18px',
                        fontFamily: 'Inter Display',
                        fontWeight: '500',
                        lineHeight: '1'
                      }}>
                        Seed
                      </div>
                      <div 
                        onClick={() => setShowSeedInfo(!showSeedInfo)}
                        style={{
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: '20px',
                          height: '20px',
                          borderRadius: '50%',
                          border: '1px solid rgba(255, 255, 255, 0.5)',
                          color: 'rgba(255, 255, 255, 0.5)',
                          fontSize: '14px'
                        }}
                      >
                        ?
                      </div>
                    </div>

                    {showSeedInfo && (
                      <div style={{
                        fontSize: '14px',
                        color: 'rgba(255, 255, 255, 0.5)',
                        marginBottom: '8px',
                        lineHeight: '1.4'
                      }}>
                        The seed determines the initial noise pattern. Using the same seed with the same parameters will generate similar images.
                      </div>
                    )}

                    <input
                      type="text"
                      value={seed}
                      onChange={handleSeedChange}
                      placeholder="Leave empty for random seed"
                      style={{
                        width: '284px',
                        padding: '8px',
                        border: '1px solid rgba(255, 255, 255, 0.1)',
                        borderRadius: '8px',
                        background: 'rgba(255, 255, 255, 0.05)',
                        color: 'white',
                        fontSize: '16px',
                        fontFamily: 'Inter Display'
                      }}
                    />
                  </div>
                </div>

                <div 
                  className={`Frame27 ${isGenerating ? 'generating' : ''}`}
                  onClick={handleGenerateImageClick}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                    marginTop: '24px',
                    cursor: isGenerating ? 'default' : 'pointer',
                    opacity: isGenerating ? 0.8 : 1,
                  }}
                >
                  <div className="GenerarImagen" style={{
                    color: '#FFC300',
                    fontSize: '18px',
                    fontFamily: 'Inter Display',
                    fontWeight: '700',
                    letterSpacing: '0.36px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px'
                  }}>
                    <span>{isGenerating ? 'GENERANDO' : 'GENERAR IMAGEN'}</span>
                    {isGenerating && (
                      <span className="generating-dots">
                        <span>.</span><span>.</span><span>.</span>
                      </span>
                    )}
                    <div className={`button-icon ${isGenerating ? 'spin' : ''}`}>
                      <Image size={20} color="#FFC300" strokeWidth={2} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="right-panel">
        <div style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          gap: '12px',
          padding: '20px',
          width: '100%'
        }}>
          <HeaderActions />
        </div>

        {userGeneratedImages && userGeneratedImages.length > 0 && (
          <div 
            ref={containerRef}
            className="generated-images-container"
            onMouseDown={handleContainerMouseDown}
            onMouseMove={handleContainerMouseMove}
            onMouseUp={handleContainerMouseUp}
            onMouseLeave={handleContainerMouseUp}
          >
            <div className="previous-images">
              {userGeneratedImages.map((image, index) => (
                <div key={index} className="Frame74">
                  <div className="Frame73">
                    <div 
                      className="Frame70" 
                      style={{
                        backgroundImage: `url(${image.image_url})`,
                        cursor: 'pointer',
                        position: 'relative',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                      }}
                      onClick={() => handleImageExpand(image.image_url)}
                    >
                      <button 
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteImage(image.image_url);
                        }}
                        className="action-button delete-button"
                        aria-label="Delete image"
                      >
                        <svg 
                          xmlns="http://www.w3.org/2000/svg" 
                          width="14" 
                          height="14" 
                          viewBox="0 0 24 24" 
                          fill="none" 
                          stroke="currentColor" 
                          strokeWidth="2.5" 
                          strokeLinecap="round" 
                          strokeLinejoin="round"
                          style={{ color: 'white' }}
                        >
                          <line x1="18" y1="6" x2="6" y2="18"></line>
                          <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                      </button>

                      <div className="image-actions">
                        <button 
                          onClick={(e) => {
                            e.stopPropagation();
                            handleImageDownload(image.image_url);
                          }}
                          style={{
                            background: 'black',
                            border: 'none',
                            borderRadius: '100px',
                            padding: '10px 24px',
                            color: 'white',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                            fontSize: '14px',
                            fontWeight: '700',
                            fontFamily: 'Inter Display'
                          }}
                        >
                          Download
                          <Download size={16} color="white" />
                        </button>
                      </div>
                    </div>
                    <button
                      onClick={() => handleGenerateMore(image)}
                      style={{
                        background: 'rgba(0, 0, 0, 0.5)',
                        border: 'none',
                        borderRadius: '100px',
                        padding: '12px 20px',
                        color: 'white',
                        cursor: 'pointer',
                        fontSize: '14px',
                        fontFamily: 'Inter Display',
                        fontWeight: '500',
                        marginTop: '12px',
                        transition: 'background-color 0.2s ease',
                        width: 'fit-content'
                      }}
                      onMouseEnter={(e) => e.currentTarget.style.background = 'rgba(0, 0, 0, 0.7)'}
                      onMouseLeave={(e) => e.currentTarget.style.background = 'rgba(0, 0, 0, 0.5)'}
                    >
                      Generar mas como esta
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        <div className="Frame67" style={{
          width: 338,
          height: 133,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
          gap: '0px'
        }}>
          <div style={{
            width: '100%',
            height: '65px',
            color: 'white',
            fontSize: '64.69px',
            fontFamily: 'Recoleta',
            fontWeight: '700',
            letterSpacing: '-1.94px',
            lineHeight: '1'
          }}>
            Grazia
          </div>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '0px'
          }}>
            <div style={{
              width: '26px',
              height: '57px',
              backgroundColor: 'transparent'
            }}></div>
            <div style={{
              color: 'white',
              fontSize: '63.94px',
              fontFamily: 'Inter Display',
              fontWeight: '700',
              letterSpacing: '-1.28px',
              lineHeight: '1'
            }}>
              Inspiración
            </div>
          </div>
        </div>

        <div className="inspiration-section">
          <div className="inspiration-grid">
            {galleryImages.map((item) => (
              <div key={item.id} className="masonry-item">
                <img 
                  src={item.image} 
                  alt="Inspiration"
                  loading="lazy"
                />
              </div>
            ))}
          </div>
        </div>

        <Footer />
      </div>

      {showPricing && <SelectProductModal onClose={() => setShowPricing(false)} />}
      {isLoading && <LoadingProgress progress={uploadProgress} />}
      {showSelectProduct && (
        <SelectProductModal 
          onClose={() => setShowSelectProduct(false)} 
          onProductSelect={handleProductSelect}
        />
      )}
      {alert && (
        <Alert 
          type={alert.type}
          message={alert.message}
          onClose={() => setAlert(null)}
        />
      )}
      {showVideoModal && (
        <VideoModal 
          videoUrl={introVideoUrl} 
          onClose={() => setShowVideoModal(false)} 
        />
      )}
      {showInsufficientCreditsModal && (
        <InsufficientCreditsModal
          onClose={() => setShowInsufficientCreditsModal(false)}
          onUpgrade={handleUpgradeClick}
          requiredCredits={requiredCredits}
          currentBalance={currentBalance}
        />
      )}
      {showAuthModal && (
        <AuthModal onClose={() => setShowAuthModal(false)} />
      )}

      {/* Expanded Image Modal */}
      {expandedImage && (
        <div 
          onClick={handleImageCollapse}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.9)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1100,
            cursor: 'pointer',
            padding: '40px'
          }}
        >
          <div 
            onClick={(e) => e.stopPropagation()}
            style={{
              position: 'relative',
              maxWidth: '90vw',
              maxHeight: '90vh',
              cursor: 'default'
            }}
          >
            <img 
              src={expandedImage} 
              alt="Expanded view"
              style={{
                maxWidth: '100%',
                maxHeight: '90vh',
                objectFit: 'contain',
                borderRadius: '12px'
              }}
            />
            <button
              onClick={handleImageCollapse}
              style={{
                position: 'absolute',
                top: '-20px',
                right: '-20px',
                background: 'white',
                border: 'none',
                borderRadius: '50%',
                width: '40px',
                height: '40px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)'
              }}
            >
              <X size={24} color="black" />
            </button>
            
            {/* Download button */}
            <button
              onClick={() => handleImageDownload(expandedImage)}
              style={{
                position: 'absolute',
                bottom: '20px',
                right: '20px',
                background: 'black',
                border: 'none',
                borderRadius: '100px',
                padding: '12px 24px',
                color: 'white',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                fontSize: '14px',
                fontWeight: '700',
                fontFamily: 'Inter Display',
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)'
              }}
            >
              Download
              <Download size={16} color="white" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MainPage;

