import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

const PaymentSuccess = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [status, setStatus] = useState('loading');

  useEffect(() => {
    const sessionId = searchParams.get('session_id');
    if (!sessionId) {
      navigate('/');
      return;
    }

    // Here you would typically:
    // 1. Verify the payment with your backend
    // 2. Update the user's credits
    // 3. Show success message
    setStatus('success');
  }, [searchParams, navigate]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-black text-white p-4">
      {status === 'loading' ? (
        <div>Verifying your payment...</div>
      ) : (
        <div className="text-center">
          <h1 className="text-2xl mb-4">Payment Successful!</h1>
          <p className="mb-4">Your credits have been added to your account.</p>
          <button 
            onClick={() => navigate('/')}
            className="bg-white text-black px-4 py-2 rounded"
          >
            Return to Dashboard
          </button>
        </div>
      )}
    </div>
  );
};

export default PaymentSuccess; 