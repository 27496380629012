import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './LegalPage.css';

const LegalPage = ({ initialSection }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (initialSection) {
      const element = document.getElementById(initialSection);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [initialSection]);

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="legal-container">
      <button onClick={handleBack} className="legal-back-button">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
          <path d="M19 12H5M12 19l-7-7 7-7"/>
        </svg>
      </button>
      
      <div className="legal-content">
        <h1>Legal Information</h1>
        
        <section id="terms" className="legal-section">
          <h2>Terms and Conditions</h2>
          <div className="legal-text">
            <h3>1. Service Description</h3>
            <p>Grazia AI, operated by Signotech LLC ("we," "our," or "the Company"), provides an artificial intelligence-powered image generation service for product visualization and marketing purposes.</p>

            <h3>2. User Rights and Responsibilities</h3>
            <p>By using our service, you agree to:</p>
            <ul>
              <li>Provide accurate and legal product images for AI training</li>
              <li>Hold necessary rights or permissions for any uploaded content</li>
              <li>Use generated images in compliance with applicable laws</li>
              <li>Not generate content that infringes on third-party rights</li>
            </ul>

            <h3>3. Intellectual Property Rights</h3>
            <p>3.1 User-Generated Content</p>
            <ul>
              <li>You retain rights to your original uploaded product images</li>
              <li>You grant us a license to use uploaded images for AI training</li>
              <li>Generated images are provided under a commercial-use license</li>
            </ul>

            <p>3.2 AI-Generated Content</p>
            <ul>
              <li>You receive a perpetual, worldwide, non-exclusive license to use generated images</li>
              <li>Generated images may not be claimed as trademarked or copyrighted works</li>
              <li>Multiple users may receive similar AI-generated outputs</li>
            </ul>

            <h3>4. Usage Restrictions</h3>
            <p>Prohibited uses include:</p>
            <ul>
              <li>Generation of illegal, harmful, or discriminatory content</li>
              <li>Misrepresentation of AI-generated images as human-created art</li>
              <li>Resale of AI-generated images as stock photos</li>
              <li>Use in ways that violate third-party rights</li>
            </ul>
          </div>
        </section>

        <section id="privacy" className="legal-section">
          <h2>Privacy Policy</h2>
          <div className="legal-text">
            <h3>1. Data Collection</h3>
            <p>We collect:</p>
            <ul>
              <li>Account information (email, name, business details)</li>
              <li>Uploaded product images</li>
              <li>Generated image history</li>
              <li>Usage patterns and preferences</li>
              <li>Payment information (processed securely by third-party providers)</li>
            </ul>

            <h3>2. Data Usage</h3>
            <p>Your data is used for:</p>
            <ul>
              <li>AI model training and improvement</li>
              <li>Service personalization</li>
              <li>Communication about our services</li>
              <li>Account management</li>
            </ul>

            <h3>3. Data Protection</h3>
            <p>We implement industry-standard security measures including:</p>
            <ul>
              <li>Encryption of sensitive data</li>
              <li>Regular security audits</li>
              <li>Access controls and monitoring</li>
              <li>Secure data storage and transmission</li>
            </ul>
          </div>
        </section>

        <section id="cookies" className="legal-section">
          <h2>Cookie Policy</h2>
          <div className="legal-text">
            <h3>1. Cookie Usage</h3>
            <p>We use cookies for:</p>
            <ul>
              <li>Essential website functionality</li>
              <li>User session management</li>
              <li>Performance optimization</li>
              <li>Analytics and service improvement</li>
            </ul>

            <h3>2. Cookie Control</h3>
            <p>Users can manage cookie preferences through:</p>
            <ul>
              <li>Browser settings</li>
              <li>Our cookie preference center</li>
              <li>Third-party opt-out tools</li>
            </ul>
          </div>
        </section>

        <section className="legal-section">
          <h2>AI Model Disclaimer</h2>
          <div className="legal-text">
            <p>Our AI models:</p>
            <ul>
              <li>Generate content based on statistical patterns</li>
              <li>May produce varying results for similar inputs</li>
              <li>Are continuously evolving and improving</li>
              <li>May have inherent limitations and biases</li>
            </ul>
            <p>We make no guarantees about:</p>
            <ul>
              <li>Exact replication of specific styles or elements</li>
              <li>Consistency between generations</li>
              <li>Suitability for specific commercial purposes</li>
            </ul>
          </div>
        </section>

        <div className="legal-footer">
          <p>Last updated: {new Date().toLocaleDateString()}</p>
          <p>Contact: legal@grazia.ai</p>
          <p>Signotech LLC, Delaware, United States</p>
        </div>
      </div>
    </div>
  );
};

export default LegalPage; 