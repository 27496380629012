import React, { useState } from 'react';
import { supabase } from '../config/supabaseConfig';
import { useNavigate } from 'react-router-dom';
import './MobileAuth.css';
import authBackground from '../assets/auth-background.png';

const MobileAuth = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSignUp, setIsSignUp] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const validateForm = () => {
    if (!email || !password) {
      setError('Please fill in all fields');
      return false;
    }
    
    if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      setError('Please enter a valid email address');
      return false;
    }
    
    if (password.length < 8) {
      setError('Password must be at least 8 characters long');
      return false;
    }
    
    return true;
  };

  const handleAuth = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    setError(null);

    try {
      if (isSignUp) {
        const { error: signUpError, data } = await supabase.auth.signUp({
          email,
          password,
        });
        if (signUpError) throw signUpError;
        
        if (data.user) {
          const { error: signInError } = await supabase.auth.signInWithPassword({
            email,
            password,
          });
          if (signInError) throw signInError;
          
          navigate('/main');
        }
      } else {
        const { error: signInError } = await supabase.auth.signInWithPassword({
          email,
          password,
        });
        if (signInError) throw signInError;
        
        navigate('/main');
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      setLoading(true);
      setError(null);
      
      const { error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
        options: {
          redirectTo: 'https://www.grazia.ai'
        }
      });
      
      if (error) throw error;
      
    } catch (error) {
      setError(error.message);
      console.error('Google sign in error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div 
      className="mobile-auth"
      style={{ '--auth-background': `url(${authBackground})` }}
    >
      <div className="mobile-auth-header">
        <div className="mobile-logo">Grazia</div>
      </div>
      
      <div className="mobile-auth-content">
        <h1>{isSignUp ? 'Create Account' : 'Welcome Back'}</h1>
        <p className="mobile-auth-subtitle">
          {isSignUp 
            ? 'Sign up to start creating AI-powered product images' 
            : 'Sign in to continue to your account'}
        </p>
        
        {error && <div className="mobile-auth-error">{error}</div>}
        
        <form onSubmit={handleAuth} className="mobile-auth-form">
          <div className="mobile-input-group">
            <label htmlFor="mobile-email">Email</label>
            <input
              id="mobile-email"
              type="email"
              placeholder="your@email.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <div className="mobile-input-group">
            <label htmlFor="mobile-password">Password</label>
            <input
              id="mobile-password"
              type="password"
              placeholder="Min. 8 characters"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>

          <button type="submit" className="mobile-auth-submit" disabled={loading}>
            {loading ? 'Please wait...' : (isSignUp ? 'Create Account' : 'Sign In')}
          </button>
        </form>

        <div className="mobile-auth-divider">
          <span>or</span>
        </div>

        <button 
          type="button"
          className="mobile-google-auth"
          onClick={handleGoogleSignIn}
          disabled={loading}
        >
          <img 
            src="https://www.google.com/favicon.ico" 
            alt="Google icon" 
            className="google-icon"
          />
          Continue with Google
        </button>

        <button 
          className="mobile-auth-toggle"
          onClick={() => {
            setIsSignUp(!isSignUp);
            setError(null);
          }}
        >
          {isSignUp 
            ? 'Already have an account? Sign In' 
            : "Don't have an account? Sign Up"}
        </button>
      </div>
    </div>
  );
};

export default MobileAuth;