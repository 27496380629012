import React, { useState, useRef, useEffect } from 'react';
import './SelectProductModal.css';
import { supabase } from '../config/supabaseConfig';
import { useAuth } from '../context/AuthContext';
import * as fal from '@fal-ai/serverless-client';
import JSZip from 'jszip';
import { Upload, X, Square, PlusSquare } from 'react-feather';  // Import icons
import InsufficientCreditsModal from './InsufficientCreditsModal';
import { handleUpgradeClick } from './HeaderActions';  // Import the handler
import Alert from './Alert';  // Import our Alert component

const SelectProductModal = ({ onClose, onProductSelect }) => {
  const [showProductForm, setShowProductForm] = useState(false);
  const [productName, setProductName] = useState('');
  const [uploadedImages, setUploadedImages] = useState([]);
  const fileInputRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const carouselRef = useRef(null);
  const [isDraggingCarousel, setIsDraggingCarousel] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [products, setProducts] = useState([]);
  const [publicProducts, setPublicProducts] = useState([]);
  const [trainingStatuses, setTrainingStatuses] = useState({});
  const [alert, setAlert] = useState(null);
  const [showInsufficientCreditsModal, setShowInsufficientCreditsModal] = useState(false);
  const [requiredCredits, setRequiredCredits] = useState(0);
  const [currentBalance, setCurrentBalance] = useState(0);

  const handleAddProductClick = () => {
    setShowProductForm(!showProductForm);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    
    const files = Array.from(e.dataTransfer.files).filter(file => file.type.startsWith('image/'));
    handleFiles(files);
  };

  const handleFileInput = (e) => {
    const files = Array.from(e.target.files).filter(file => file.type.startsWith('image/'));
    handleFiles(files);
  };

  const handleFiles = (files) => {
    const newImages = files.map(file => ({
      url: URL.createObjectURL(file),
      file: file
    }));

    setUploadedImages(prev => {
      const combined = [...prev, ...newImages];
      // Limit to 20 images
      return combined.slice(0, 20);
    });
  };

  const handleDeleteImage = (indexToDelete) => {
    setUploadedImages(prev => 
      prev.filter((_, index) => index !== indexToDelete)
    );
  };

  // Optional: Add scroll buttons functionality
  const handleScroll = (direction) => {
    if (carouselRef.current) {
      const scrollAmount = direction === 'left' ? -300 : 300;
      carouselRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };

  const handleMouseDown = (e) => {
    setIsDraggingCarousel(true);
    setStartX(e.pageX - carouselRef.current.offsetLeft);
    setScrollLeft(carouselRef.current.scrollLeft);
  };

  const handleMouseLeave = () => {
    setIsDraggingCarousel(false);
  };

  const handleMouseUp = () => {
    setIsDraggingCarousel(false);
  };

  const handleMouseMove = (e) => {
    if (!isDraggingCarousel) return;
    e.preventDefault();
    const x = e.pageX - carouselRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Multiply by 2 for faster scrolling
    carouselRef.current.scrollLeft = scrollLeft - walk;
  };

  const showAlert = (type, message) => {
    setAlert({ type, message });
    setTimeout(() => setAlert(null), 3000);
  };

  const uploadAllImagesToSupabase = async (images) => {
    try {
      const uploadPromises = images.map(async (image) => {
        const url = await uploadToSupabase(image.file);
        return url;
      });

      return await Promise.all(uploadPromises);
    } catch (error) {
      console.error('Error uploading images:', error);
      throw new Error('Failed to upload all images');
    }
  };

  const fetchProducts = async () => {
    try {
      // Fetch user's products
      const { data: userProducts, error: userError } = await supabase
        .from('products')
        .select('*')
        .eq('user_id', user.id)
        .order('created_at', { ascending: false });

      if (userError) throw userError;

      // Fetch public products
      const { data: publicProductsData, error: publicError } = await supabase
        .from('products')
        .select('*')
        .eq('public_product', true)
        .neq('user_id', user.id) // Exclude user's own products
        .eq('training_status', 'READY') // Only show ready products
        .order('created_at', { ascending: false });

      if (publicError) throw publicError;

      setProducts(userProducts || []);
      setPublicProducts(publicProductsData || []);
    } catch (error) {
      console.error('Error fetching products:', error);
      showAlert('error', 'Failed to load products');
    }
  };

  useEffect(() => {
    fetchProducts();
  }, [user.id]);

  useEffect(() => {
    const checkTrainingStatus = async (product) => {
      try {
        // If the product is already READY and has a LoRA URL, skip checking
        if (product.training_status === 'READY' && product.lora_file_url) {
          return;
        }

        // If the product failed, skip checking
        if (['FAILED', 'FAIL'].includes(product.training_status?.toUpperCase())) {
          return;
        }

        // Configure fal client
        const apiKey = process.env.REACT_APP_FLUX_API_KEY;
        if (!apiKey) {
          throw new Error('API key not found');
        }

        fal.config({
          credentials: apiKey,
        });

        // If the product is COMPLETED/COMPLETE but doesn't have a LoRA URL yet
        if (['COMPLETED', 'COMPLETE'].includes(product.training_status?.toUpperCase())) {
          try {
            const result = await fal.queue.result("fal-ai/flux-lora-fast-training", {
              requestId: product.request_id,
            });

            if (result?.diffusers_lora_file?.url) {
              // Update Supabase with both status and LoRA URL
              const { error } = await supabase
                .from('products')
                .update({ 
                  training_status: 'READY',
                  lora_file_url: result.diffusers_lora_file.url
                })
                .eq('id', product.id);

              if (error) {
                console.error('Error updating product with LoRA URL:', error);
              }

              // Update local state
              setTrainingStatuses(prev => ({
                ...prev,
                [product.id]: {
                  status: 'READY',
                  progress: 100,
                  current_step: 1000
                }
              }));
            }
            return;
          } catch (resultError) {
            console.error('Error getting training result:', resultError);
          }
        }

        // For products still in training, check status
        const status = await fal.queue.status("fal-ai/flux-lora-fast-training", {
          requestId: product.request_id,
          logs: true
        });

        // Only update if status has changed
        if (status.status !== product.training_status) {
          if (status.status === 'COMPLETED' || status.status === 'COMPLETE') {
            try {
              const result = await fal.queue.result("fal-ai/flux-lora-fast-training", {
                requestId: product.request_id,
              });

              if (result?.diffusers_lora_file?.url) {
                // Update Supabase with both status and LoRA URL
                const { error } = await supabase
                  .from('products')
                  .update({ 
                    training_status: 'READY',
                    lora_file_url: result.diffusers_lora_file.url
                  })
                  .eq('id', product.id);

                if (error) {
                  console.error('Error updating product with LoRA URL:', error);
                }

                // Update local state
                setTrainingStatuses(prev => ({
                  ...prev,
                  [product.id]: {
                    status: 'READY',
                    progress: 100,
                    current_step: 1000
                  }
                }));
                return;
              }
            } catch (resultError) {
              console.error('Error getting training result:', resultError);
            }
          }

          // Update status for non-completed states or if getting result failed
          const { error } = await supabase
            .from('products')
            .update({ 
              training_status: status.status,
            })
            .eq('id', product.id);

          if (error) {
            console.error('Error updating product status:', error);
          }

          // Update local state
          setTrainingStatuses(prev => ({
            ...prev,
            [product.id]: {
              status: status.status,
              progress: status.logs?.current_step ? (status.logs.current_step / 1000) * 100 : 0,
              current_step: status.logs?.current_step || 0
            }
          }));
        }
      } catch (error) {
        // Only log error if it's not a 404 for completed requests
        if (!error.message?.includes('404')) {
          console.error('Error checking status:', error);
        }
      }
    };

    // Only poll for products that are in processing or pending state
    const processingProducts = products.filter(p => {
      const status = p.training_status?.toUpperCase();
      return status === 'PROCESSING' || status === 'PENDING';
    });

    let pollingInterval;
    if (processingProducts.length > 0) {
      // Increase polling interval to 10 seconds
      pollingInterval = setInterval(() => {
        processingProducts.forEach(product => {
          checkTrainingStatus(product);
        });
      }, 10000); // Changed from 5000 to 10000 ms
    }

    // Cleanup
    return () => {
      if (pollingInterval) {
        clearInterval(pollingInterval);
      }
    };
  }, [products]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      if (uploadedImages.length < 5) {
        throw new Error('Please upload at least 5 images');
      }

      // Get the cost for model training
      const { data: priceData, error: priceError } = await supabase
        .from('credit_prices')
        .select('credit_cost')
        .eq('operation_type', 'MODEL_TRAINING')
        .single();

      if (priceError) {
        if (priceError.code === 'PGRST116') {
          throw new Error('Model training price not configured. Please contact support.');
        }
        throw priceError;
      }

      // Get user's current balance
      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('credits_balance')
        .eq('id', user.id)
        .single();

      if (userError) throw userError;

      const creditCost = Number(parseFloat(priceData.credit_cost).toFixed(2));
      const currentBalance = Number(parseFloat(userData.credits_balance).toFixed(2));

      // Check if user has enough credits
      if (currentBalance < creditCost) {
        setShowInsufficientCreditsModal(true);
        setRequiredCredits(creditCost);
        setCurrentBalance(currentBalance);
        return;
      }

      // Create credit transaction (trigger will handle balance update)
      const { error: transactionError } = await supabase
        .from('credit_transactions')
        .insert({
          user_id: user.id,
          operation_type: 'MODEL_TRAINING',
          amount: -creditCost,
          description: 'Credit deduction for model training',
          created_at: new Date().toISOString()
        });

      if (transactionError) {
        if (transactionError.message.includes('Insufficient credits')) {
          throw new Error('Insufficient credits for this operation');
        }
        throw transactionError;
      }

      // Upload images to Supabase
      const imageUrls = await uploadAllImagesToSupabase(uploadedImages);

      // Configure fal client
      const apiKey = process.env.REACT_APP_FLUX_API_KEY;
      if (!apiKey) {
        throw new Error('API key not found');
      }

      fal.config({
        credentials: apiKey,
      });

      // Create zip and continue with FAL processing
      const zip = new JSZip();
      uploadedImages.forEach((image, index) => {
        zip.file(`image${index + 1}.jpg`, image.file);
      });
      const zipBlob = await zip.generateAsync({ type: "blob" });
      const zipFile = new File([zipBlob], "training_images.zip", { type: "application/zip" });

      const fileUrl = await fal.storage.upload(zipFile);

      const { request_id } = await fal.queue.submit("fal-ai/flux-lora-fast-training", {
        input: {
          images_data_url: fileUrl,
          trigger_word: productName,
          steps: 1000,
          create_masks: true,
        },
      });

      // Save to Supabase
      const { error: dbError } = await supabase
        .from('products')
        .insert([{
          name: productName,
          image_url: imageUrls[0], // First image as preview
          images_urls: imageUrls,  // All image URLs
          user_id: user.id,
          request_id: request_id,
          training_status: 'processing'
        }]);

      if (dbError) throw dbError;

      showAlert('success', 'Product created successfully! Training has started.');
      
      // Reset form
      setProductName('');
      setUploadedImages([]);
      setShowProductForm(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }

      // Refresh products list
      await fetchProducts();

    } catch (error) {
      console.error('Error in model training:', error);
      showAlert('error', error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const uploadToSupabase = async (file) => {
    try {
      const fileExt = file.name.split('.').pop();
      const fileName = `${user.id}/${Date.now()}-${Math.random().toString(36).substring(2)}.${fileExt}`;
      const filePath = `products/${fileName}`;

      const maxSize = 10 * 1024 * 1024;
      if (file.size > maxSize) {
        throw new Error(`File size must be less than 10MB`);
      }

      const { data, error } = await supabase.storage
        .from('product-images')
        .upload(filePath, file, {
          cacheControl: '3600',
          upsert: false,
          contentType: file.type,
          maxSize: maxSize
        });

      if (error) throw error;

      const { data: { publicUrl } } = supabase.storage
        .from('product-images')
        .getPublicUrl(filePath);

      return publicUrl;
    } catch (error) {
      console.error('Error in uploadToSupabase:', error);
      throw new Error(`Upload failed: ${error.message}`);
    }
  };

  const renderTrainingStatus = (product) => {
    const status = trainingStatuses[product.id] || {
      status: product.training_status?.toUpperCase(),
      progress: 0,
      current_step: 0
    };

    const normalizedStatus = status.status?.toUpperCase();

    switch (normalizedStatus) {
      case 'PROCESSING':
        return (
          <div className="training-status processing">
            <div className="status-text">TRAINING</div>
            <div className="progress-info">
              {Math.round(status.progress)}% ({status.current_step}/1000)
            </div>
            <div className="progress-bar">
              <div 
                className="progress-fill" 
                style={{width: `${status.progress}%`}}
              />
            </div>
          </div>
        );
      case 'COMPLETED':
      case 'COMPLETE':
      case 'READY':
        return <div className="training-status completed">READY</div>;
      case 'FAILED':
      case 'FAIL':
        return <div className="training-status failed">FAILED</div>;
      case 'PENDING':
        return <div className="training-status pending">PENDING</div>;
      default:
        return <div className="training-status">{status.status}</div>;
    }
  };

  const handleProductSelect = (product) => {
    onProductSelect(product);
    onClose();
  };

  return (
    <div className="modal-overlay">
      <div className="SelectProductModal">
        <div className="Frame61">
          <div className="Frame62">
            <div className="IntroText">
              <div className="SelectOneProduct">Select one product</div>
              <div className="ElProductoQueSeleccionesSeUtilizaraParaGenerarTuContenido">
                El producto que selecciones se utilizara<br/>para generar tu contenido
              </div>
            </div>
            <div className="AddAProductButton" 
              onClick={handleAddProductClick}
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                background: '#FFC300',
                padding: '15px 30px',
                borderRadius: '14px',
                cursor: 'pointer'
              }}
            >
              <div className="AgregaTuPropioProducto" style={{
                color: 'black',
                fontSize: '18px',
                fontFamily: 'Inter Display',
                fontWeight: '700'
              }}>
                Agrega tu propio producto
              </div>
              <PlusSquare size={24} color="black" />
            </div>
          </div>

          {showProductForm && (
            <div className="Frame85">
              <div className="Frame84">
                <div className="Frame78">
                  <div className="CualEsElNombreDeTuProducto">
                    Cual es el nombre de tu producto?
                  </div>
                  <input
                    type="text"
                    className="Rectangle78"
                    value={productName}
                    onChange={(e) => setProductName(e.target.value)}
                  />
                </div>
                <div className="Frame83">
                  <div className="Frame86">
                    <div className="SubeLasImagenesDeTuProducto">
                      Sube las imágenes de tu producto
                    </div>
                    <div className="MNimoDe5ImGenes10ImGenesFondoBlancoRecomendado">
                      Mínimo de 10 imágenes - fondo blanco (opcional)
                    </div>
                  </div>
                  <div className="Frame80">
                    <div
                      className={`Frame79 ${isDragging ? 'dragging' : ''}`}
                      onClick={handleUploadClick}
                      onDragOver={handleDragOver}
                      onDragLeave={handleDragLeave}
                      onDrop={handleDrop}
                      style={{
                        width: '100%',
                        maxWidth: '300px',
                        height: '200px',
                        border: '1px dashed rgba(255, 255, 255, 0.3)',
                        borderRadius: '12px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '16px',
                        cursor: 'pointer',
                        transition: 'all 0.2s ease',
                        background: isDragging ? 'rgba(255, 255, 255, 0.05)' : 'transparent',
                        padding: '16px'
                      }}
                    >
                      <input
                        type="file"
                        ref={fileInputRef}
                        onChange={handleFileInput}
                        accept="image/*"
                        multiple
                        style={{ display: 'none' }}
                      />
                      
                      <Upload 
                        size={32} 
                        color="white" 
                        style={{ opacity: 0.5 }}
                      />
                      
                      <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '8px',
                        textAlign: 'center',
                        width: '100%',
                        padding: '0 8px'
                      }}>
                        <div style={{
                          color: 'white',
                          fontSize: '16px',
                          fontFamily: 'Inter Display',
                          fontWeight: '500'
                        }}>
                          Click o arrastra
                        </div>
                        <div style={{
                          color: 'rgba(255, 255, 255, 0.5)',
                          fontSize: '14px',
                          fontFamily: 'Inter Display',
                          maxWidth: '100%'
                        }}>
                          Mínimo de 10 imágenes - fondo blanco (opcional)
                        </div>
                      </div>
                    </div>
                    <div 
                      className="Frame81" 
                      ref={carouselRef}
                      onMouseDown={handleMouseDown}
                      onMouseLeave={handleMouseLeave}
                      onMouseUp={handleMouseUp}
                      onMouseMove={handleMouseMove}
                      style={{ cursor: isDraggingCarousel ? 'grabbing' : 'grab' }}
                    >
                      {uploadedImages.map((image, index) => (
                        <div key={index} className="image-container">
                          <img src={image.url} alt={`Uploaded ${index + 1}`} />
                          <button
                            className="delete-button"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteImage(index);
                            }}
                          >
                            ×
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="AddAProductButton" onClick={handleSubmit} disabled={isLoading}>
                <div className="CrearProducto">
                  {isLoading ? 'CREATING PRODUCT...' : 'CREAR PRODUCTO'}
                </div>
              </div>
            </div>
          )}

          {/* Optional: Add scroll buttons */}
          {uploadedImages.length > 4 && (
            <div className="carousel-controls">
              <button onClick={() => handleScroll('left')} className="scroll-button">
                ←
              </button>
              <button onClick={() => handleScroll('right')} className="scroll-button">
                →
              </button>
            </div>
          )}

          <div className="Frame60" style={{ height: 'auto', marginBottom: '30px' }}>
            <div className="ProductosDePrueba">Productos Públicos</div>
            <div 
              className="CarrouselProductSlider"
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fill, minmax(152px, 1fr))',
                gap: '20px',
                width: '100%',
                overflowX: 'visible'
              }}
            >
              {publicProducts.map((product) => (
                <div key={product.id} className="Frame52" style={{ width: '152px', height: 'auto' }}>
                  <div style={{ width: '100%' }}>
                    <img 
                      className="Rectangle70" 
                      src={product.image_url || "https://via.placeholder.com/152x152"} 
                      alt={product.name}
                      style={{ width: '100%', height: '152px' }}
                    />
                    <div className="Frame49" style={{ marginTop: '12px' }}>
                      <div className="Frame50">
                        <div className="HeadphonesBoseUltraSound" style={{ 
                          fontSize: '14px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          width: '100%',
                          textAlign: 'center'
                        }}>
                          {product.name}
                        </div>
                      </div>
                      <div className="Frame48" style={{ width: '100%' }}>
                        <div className="Frame51">
                          <div className="Estado">
                            <div className="training-status completed">READY</div>
                          </div>
                        </div>
                        <div 
                          className="Frame47" 
                          onClick={() => handleProductSelect(product)}
                          style={{ 
                            cursor: 'pointer',
                            width: '100%',
                            padding: '8px 16px'
                          }}
                        >
                          <div className="Seleccionar">Seleccionar</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {publicProducts.length === 0 && (
                <div className="FutureProducts" style={{ gridColumn: '1 / -1' }}>
                  <div className="Frame63">
                    <div className="TusProductosCreadosAparecerNAqu">
                      No hay productos públicos disponibles
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="Frame64" style={{ height: 'auto' }}>
            <div className="MisProductos">Mis productos</div>
            {products.length > 0 ? (
              <div 
                className="CarrouselProductSlider"
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(auto-fill, minmax(152px, 1fr))',
                  gap: '20px',
                  width: '100%',
                  overflowX: 'visible'
                }}
              >
                {products.map((product) => (
                  <div key={product.id} className="Frame52" style={{ width: '152px', height: 'auto' }}>
                    <div style={{ width: '100%' }}>
                      <img 
                        className="Rectangle70" 
                        src={product.image_url || "https://via.placeholder.com/152x152"} 
                        alt={product.name}
                        style={{ width: '100%', height: '152px' }}
                      />
                      <div className="Frame49" style={{ marginTop: '12px' }}>
                        <div className="Frame50">
                          <div className="HeadphonesBoseUltraSound" style={{ 
                            fontSize: '14px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: '100%',
                            textAlign: 'center'
                          }}>
                            {product.name}
                          </div>
                        </div>
                        <div className="Frame48" style={{ width: '100%' }}>
                          <div className="Frame51">
                            <div className="Estado">
                              {renderTrainingStatus(product)}
                            </div>
                          </div>
                          <div 
                            className="Frame47" 
                            onClick={() => handleProductSelect(product)}
                            style={{ 
                              cursor: 'pointer',
                              width: '100%',
                              padding: '8px 16px'
                            }}
                          >
                            <div className="Seleccionar">Seleccionar</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="FutureProducts">
                <div className="Frame63">
                  <div className="TusProductosCreadosAparecerNAqu">
                    Tus productos creados aparecerán aquí
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div 
          className="Frame65" 
          onClick={onClose}
          style={{
            position: 'absolute',
            top: '20px',
            right: '20px',
            width: '40px',
            height: '40px',
            background: 'white',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer'
          }}
        >
          <X size={24} color="black" />
        </div>
      </div>

      {showInsufficientCreditsModal && (
        <InsufficientCreditsModal
          onClose={() => setShowInsufficientCreditsModal(false)}
          onUpgrade={handleUpgradeClick}
        />
      )}

      {alert && (
        <div 
          style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            width: '300px',
            height: '48px',
            backgroundColor: 
              alert.type === 'error' ? '#FF4444' : 
              alert.type === 'warning' ? '#FFA500' : 
              alert.type === 'info' ? '#2196F3' : '#4CAF50',
            borderRadius: '8px',
            display: 'flex',
            alignItems: 'center',
            padding: '0 16px',
            gap: '10px',
            color: 'white',
            fontFamily: 'Inter Display',
            fontSize: '14px',
            lineHeight: '1.2',
            zIndex: 10000,
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
            animation: 'slideIn 0.3s ease-out'
          }}
        >
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path 
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM10 6v4m0 2h.01" 
              stroke="currentColor" 
              strokeWidth="2" 
              strokeLinecap="round"
            />
          </svg>
          <span style={{ 
            overflow: 'hidden', 
            textOverflow: 'ellipsis', 
            whiteSpace: 'nowrap' 
          }}>
            {alert.message}
          </span>
        </div>
      )}
    </div>
  );
};

export default SelectProductModal; 