import React from 'react';
import './LoadingProgress.css';

const LoadingProgress = ({ progress }) => {
  return (
    <div className="loading-container">
      <div className="progress-bar">
        <div 
          className="progress-fill"
          style={{ width: `${progress}%` }}
        />
      </div>
      <div className="progress-text">{Math.round(progress)}%</div>
    </div>
  );
};

export default LoadingProgress; 