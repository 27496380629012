import React, { useState, useEffect, useRef } from 'react';
import { ArrowLeft, Plus, ArrowUp, Upload } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../config/supabaseConfig';
import { useAuth } from '../context/AuthContext';
import { trainModel } from '../api_calls/trainModel';
import './MobileProductMenu.css';
import InsufficientCreditsModal from '../components/InsufficientCreditsModal';
import { handleUpgradeClick } from '../components/HeaderActions';
import * as fal from '@fal-ai/serverless-client';

const MobileProductMenu = ({ onProductSelect }) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [showProductForm, setShowProductForm] = useState(false);
  const [products, setProducts] = useState([]);
  const [publicProducts, setPublicProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [productName, setProductName] = useState('');
  const [uploadedImages, setUploadedImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const fileInputRef = useRef(null);
  const [showInsufficientCreditsModal, setShowInsufficientCreditsModal] = useState(false);
  const [requiredCredits, setRequiredCredits] = useState(0);
  const [currentBalance, setCurrentBalance] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [alert, setAlert] = useState(null);
  const [trainingStatuses, setTrainingStatuses] = useState({});

  useEffect(() => {
    fetchProducts();
  }, [user?.id]);

  useEffect(() => {
    const checkTrainingStatus = async (product) => {
      try {
        // If the product is already READY and has a LoRA URL, skip checking
        if (product.training_status === 'READY' && product.lora_file_url) {
          return;
        }

        // If the product failed, skip checking
        if (['FAILED', 'FAIL'].includes(product.training_status?.toUpperCase())) {
          return;
        }

        const apiKey = process.env.REACT_APP_FLUX_API_KEY;
        if (!apiKey) {
          throw new Error('API key not found');
        }

        fal.config({
          credentials: apiKey,
        });

        // If the product is COMPLETED/COMPLETE but doesn't have a LoRA URL yet
        if (['COMPLETED', 'COMPLETE'].includes(product.training_status?.toUpperCase())) {
          try {
            const result = await fal.queue.result("fal-ai/flux-lora-fast-training", {
              requestId: product.request_id,
            });

            if (result?.diffusers_lora_file?.url) {
              // Update Supabase with both status and LoRA URL
              const { error } = await supabase
                .from('products')
                .update({ 
                  training_status: 'READY',
                  lora_file_url: result.diffusers_lora_file.url
                })
                .eq('id', product.id);

              if (error) {
                console.error('Error updating product with LoRA URL:', error);
              }

              // Update local state
              setTrainingStatuses(prev => ({
                ...prev,
                [product.id]: {
                  status: 'READY',
                  progress: 100,
                  currentStep: 1000
                }
              }));

              // Refresh products list
              await fetchProducts();
              return;
            }
          } catch (resultError) {
            console.error('Error getting training result:', resultError);
          }
        }

        // For products still in training, check status
        const status = await fal.queue.status("fal-ai/flux-lora-fast-training", {
          requestId: product.request_id,
          logs: true
        });

        // Only update if status has changed
        if (status.status !== product.training_status) {
          if (status.status === 'COMPLETED' || status.status === 'COMPLETE') {
            try {
              const result = await fal.queue.result("fal-ai/flux-lora-fast-training", {
                requestId: product.request_id,
              });

              if (result?.diffusers_lora_file?.url) {
                // Update Supabase with both status and LoRA URL
                const { error } = await supabase
                  .from('products')
                  .update({ 
                    training_status: 'READY',
                    lora_file_url: result.diffusers_lora_file.url
                  })
                  .eq('id', product.id);

                if (error) {
                  console.error('Error updating product with LoRA URL:', error);
                }

                // Update local state
                setTrainingStatuses(prev => ({
                  ...prev,
                  [product.id]: {
                    status: 'READY',
                    progress: 100,
                    currentStep: 1000
                  }
                }));

                // Refresh products list
                await fetchProducts();
                return;
              }
            } catch (resultError) {
              console.error('Error getting training result:', resultError);
            }
          }

          // Update status for non-completed states or if getting result failed
          const { error } = await supabase
            .from('products')
            .update({ 
              training_status: status.status,
            })
            .eq('id', product.id);

          if (error) {
            console.error('Error updating product status:', error);
          }

          // Parse logs for progress information
          let progress = 0;
          let currentStep = 0;
          let totalSteps = 1000;

          if (status.logs) {
            const stepMatch = status.logs.match(/Step (\d+)\/(\d+)/);
            if (stepMatch) {
              currentStep = parseInt(stepMatch[1]);
              totalSteps = parseInt(stepMatch[2]);
              progress = Math.round((currentStep / totalSteps) * 100);
            }
          }

          // Update local state
          setTrainingStatuses(prev => ({
            ...prev,
            [product.id]: {
              status: status.status,
              progress,
              currentStep
            }
          }));
        }
      } catch (error) {
        // Only log error if it's not a 404 for completed requests
        if (!error.message?.includes('404')) {
          console.error('Error checking status:', error);
        }
      }
    };

    const processingProducts = products.filter(p => 
      p.training_status === 'PROCESSING' || p.training_status === 'PENDING'
    );

    if (processingProducts.length > 0) {
      const interval = setInterval(() => {
        processingProducts.forEach(checkTrainingStatus);
      }, 10000);

      return () => clearInterval(interval);
    }
  }, [products]);

  const fetchProducts = async () => {
    try {
      console.log('Fetching products...');
      // Fetch user's products - remove the status filter
      const { data: userProducts, error: userError } = await supabase
        .from('products')
        .select(`
          id,
          user_id,
          name,
          image_url,
          images_urls,
          training_status,
          lora_file_url,
          lora_model_path,
          request_id,
          created_at
        `)
        .eq('user_id', user.id)
        .order('created_at', { ascending: false });

      if (userError) throw userError;

      // Set all user products without filtering
      console.log('User products:', userProducts);
      setProducts(userProducts || []);

      // For public products, we still want to show only ready ones
      const { data: publicProductsData, error: publicError } = await supabase
        .from('products')
        .select()
        .eq('public_product', true)
        .eq('training_status', 'READY')  // Keep this filter for public products
        .not('lora_file_url', 'is', null)
        .order('created_at', { ascending: false });

      if (publicError) throw publicError;

      setPublicProducts(publicProductsData || []);
    } catch (error) {
      console.error('Error fetching products:', error);
      setError('Error al cargar los productos');
    } finally {
      setLoading(false);
    }
  };

  const handleFileInput = (e) => {
    const files = Array.from(e.target.files).filter(file => file.type.startsWith('image/'));
    handleFiles(files);
  };

  const handleFiles = (files) => {
    const newImages = files.map(file => ({
      url: URL.createObjectURL(file),
      file: file
    }));

    setUploadedImages(prev => {
      const combined = [...prev, ...newImages];
      return combined.slice(0, 20); // Limit to 20 images
    });
  };

  const handleCreateProduct = async () => {
    try {
      setIsLoading(true);
      setError('');

      const product = await trainModel({
        productName: productName.trim(),
        uploadedImages,
        user,
        onInsufficientCredits: (required, current) => {
          setShowInsufficientCreditsModal(true);
          setRequiredCredits(required);
          setCurrentBalance(current);
        }
      });

      // Reset form
      setProductName('');
      setUploadedImages([]);
      setShowProductForm(false);

      // Refresh products list
      await fetchProducts();

    } catch (error) {
      console.error('Error creating product:', error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const files = Array.from(e.dataTransfer.files).filter(file => file.type.startsWith('image/'));
    handleFiles(files);
  };

  const handleDeleteImage = (indexToDelete) => {
    setUploadedImages(prev => 
      prev.filter((_, index) => index !== indexToDelete)
    );
  };

  const showAlert = (type, message) => {
    setAlert({ type, message });
    setTimeout(() => setAlert(null), 3000);
  };

  const handleProductSelect = (product) => {
    console.log('Attempting to select product:', product);
    
    // Check product status
    if (product.training_status !== 'READY' || !product.lora_file_url) {
      let message;
      switch (product.training_status?.toUpperCase()) {
        case 'PROCESSING':
          message = 'Este producto está en entrenamiento. Por favor, espera a que termine.';
          break;
        case 'PENDING':
          message = 'Este producto está pendiente de entrenamiento.';
          break;
        case 'FAILED':
          message = 'El entrenamiento de este producto falló. Por favor, intenta crearlo nuevamente.';
          break;
        default:
          message = 'Este producto aún no está listo para usar.';
      }
      showAlert('warning', message);
      return;
    }

    navigate('/main', { 
      state: { 
        selectedProduct: product
      }
    });
  };

  const renderTrainingStatus = (product) => {
    const status = trainingStatuses[product.id] || {
      status: product.training_status?.toUpperCase(),
      progress: 0,
      currentStep: 0,
      totalSteps: 100,
      currentEpoch: 0,
      totalEpochs: 0,
      lossValue: null
    };

    const normalizedStatus = status.status?.toUpperCase();

    const getStatusDisplay = () => {
      switch (normalizedStatus) {
        case 'PROCESSING':
          return (
            <div className="training-status-container">
              <div className="training-status-text">TRAINING</div>
              <div className="training-progress-bar">
                <div 
                  className="training-progress-fill"
                  style={{ width: `${status.progress || 0}%` }}
                />
              </div>
              <div className="training-details">
                {status.currentEpoch > 0 && (
                  <span className="training-epoch">
                    Epoch {status.currentEpoch}/{status.totalEpochs}
                  </span>
                )}
                {status.currentStep > 0 && (
                  <span className="training-step">
                    Step {status.currentStep}/{status.totalSteps}
                  </span>
                )}
                {status.lossValue && (
                  <span className="training-loss">
                    Loss: {status.lossValue}
                  </span>
                )}
              </div>
            </div>
          );
        case 'COMPLETED':
        case 'COMPLETE':
        case 'READY':
          return <span className="status-badge completed">COMPLETED</span>;
        case 'FAILED':
        case 'FAIL':
          return <span className="status-badge error">ERROR</span>;
        case 'PENDING':
          return <span className="status-badge pending">PENDING</span>;
        default:
          return status.status;
      }
    };

    return getStatusDisplay();
  };

  return (
    <div className="mobile-product-menu">
      <div className="mobile-nav">
        <button 
          className="mobile-nav-back"
          onClick={() => navigate('/main')}
        >
          <ArrowLeft size={24} />
        </button>
      </div>

      <div className="mobile-product-header">
        <h1>Selecciona-Crea un producto</h1>
        <p>El producto que selecciones se utilizara para generar tu contenido</p>
      </div>

      <div className="mobile-product-grid">
        <div className="mobile-premium-section">
          <div className="mobile-premium-feature">
            <ArrowUp size={20} />
            Premium Feature
          </div>
          <button 
            className="mobile-create-product-button"
            onClick={() => setShowProductForm(!showProductForm)}
          >
            <div className="mobile-create-product-content">
              <span>Crea tu propio producto</span>
              <Plus size={20} />
            </div>
          </button>

          {showProductForm && (
            <div className="mobile-product-form">
              <h3>Cual es el nombre de tu producto?</h3>
              <input 
                type="text" 
                className="mobile-product-input"
                placeholder="Nombre del producto"
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
              />

              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileInput}
                accept="image/*"
                multiple
                style={{ display: 'none' }}
              />

              <button 
                className="mobile-upload-button"
                onClick={() => fileInputRef.current?.click()}
              >
                <Upload size={20} />
                <span>Subir imagenes ({uploadedImages.length})</span>
              </button>

              <div className="mobile-images-preview">
                {uploadedImages.map((image, index) => (
                  <div key={index} className="image-container">
                    <img src={image.url} alt={`Upload ${index + 1}`} />
                    <button
                      className="delete-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteImage(index);
                      }}
                    >
                      ×
                    </button>
                  </div>
                ))}
              </div>

              {error && (
                <div className="mobile-error-message">
                  {error}
                </div>
              )}

              <button 
                className="mobile-create-button"
                onClick={handleCreateProduct}
                disabled={isLoading}
              >
                <span>{isLoading ? 'CREANDO...' : 'CREAR PRODUCTO'}</span>
                <Plus size={20} />
              </button>
              <div className="mobile-divider"></div>
            </div>
          )}
        </div>

        <div className="mobile-products-section">
          <h2 className="mobile-products-title">Mis Productos</h2>
          <div className="mobile-products-carousel">
            <div className="mobile-products-list">
              {products.map((product) => (
                <div 
                  key={product.id} 
                  className="mobile-product-item"
                  data-status={product.training_status?.toUpperCase()}
                  onClick={() => handleProductSelect(product)}
                >
                  <div className="mobile-product-image-container">
                    <img
                      src={product.image_url}
                      alt={product.name}
                      className="mobile-product-image"
                    />
                    {renderTrainingStatus(product)}
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                    <span className="mobile-product-title">
                      {product.name}
                    </span>
                    <div className="mobile-product-status">
                      {renderTrainingStatus(product)}
                    </div>
                  </div>
                </div>
              ))}
              {products.length === 0 && !loading && (
                <div className="mobile-empty-state">
                  No tienes productos creados
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="mobile-trial-products-section">
          <div className="mobile-trial-header">
            <h2 className="mobile-trial-title">Productos de prueba</h2>
            <span className="mobile-trial-badge">GRATIS</span>
          </div>
          <p className="mobile-trial-description">
            El producto que selecciones se utilizara para generar tu contenido
          </p>
          <div className="mobile-trial-carousel">
            <div className="mobile-trial-list">
              {publicProducts.map((product) => (
                <div 
                  key={product.id} 
                  className="mobile-product-item"
                  onClick={() => handleProductSelect(product)}
                >
                  <div className="mobile-product-image-container">
                    <img
                      src={product.image_url}
                      alt={product.name}
                      className="mobile-product-image"
                    />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                    <span className="mobile-product-title">
                      {product.name}
                    </span>
                    <div className="mobile-product-status">
                      {renderTrainingStatus(product)}
                    </div>
                  </div>
                </div>
              ))}
              {publicProducts.length === 0 && !loading && (
                <div className="mobile-empty-state">
                  No hay productos de prueba disponibles
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {showInsufficientCreditsModal && (
        <InsufficientCreditsModal
          onClose={() => setShowInsufficientCreditsModal(false)}
          onUpgrade={handleUpgradeClick}
        />
      )}

      {alert && (
        <div className={`mobile-alert mobile-alert-${alert.type}`}>
          {alert.message}
        </div>
      )}
    </div>
  );
};

export default MobileProductMenu;