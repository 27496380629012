import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../context/AuthContext';
import { supabase } from '../config/supabaseConfig';
import ReactPlayer from 'react-player';
import './MobileMainPage.css';
import { Download, X, Zap, Aperture, ChevronDown, MessageCircle } from 'react-feather';
import { useNavigate, useLocation } from 'react-router-dom';
import { generateImage } from '../api_calls/generateImage';
import InsufficientCreditsModal from '../components/InsufficientCreditsModal';
import * as fal from '@fal-ai/serverless-client';
import AuthModal from '../components/AuthModal';
import MobilePricingPlansModal from './MobilePricingPlansModal';
import Toast from '../components/Toast';

const sizeOptions = [
  { value: "1:1", label: "Square (1:1)" },
  { value: "4:3", label: "Horizontal (4:3)" },
  { value: "3:4", label: "Vertical (3:4)" },
  { value: "16:9", label: "Horizontal (16:9)" },
  { value: "9:16", label: "Portrait (9:16)" }
];

const MobileMainPage = () => {
  const { user, signOut } = useAuth();
  const [creditsBalance, setCreditsBalance] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [introVideoUrl, setIntroVideoUrl] = useState('');
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [prompt, setPrompt] = useState('');
  const [userGeneratedImages, setUserGeneratedImages] = useState([]);
  const [expandedImage, setExpandedImage] = useState(null);
  const containerRef = useRef(null);
  const [isDraggingContainer, setIsDraggingContainer] = useState(false);
  const [startDragX, setStartDragX] = useState(0);
  const [scrollLeftStart, setScrollLeftStart] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const [isGenerating, setIsGenerating] = useState(false);
  const [showInsufficientCreditsModal, setShowInsufficientCreditsModal] = useState(false);
  const [requiredCredits, setRequiredCredits] = useState(0);
  const [currentBalance, setCurrentBalance] = useState(0);
  const [error, setError] = useState(null);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [showPricing, setShowPricing] = useState(false);
  const [selectedGuidanceImage, setSelectedGuidanceImage] = useState(null);
  const [showAdvancedControls, setShowAdvancedControls] = useState(false);
  const [guidanceScale, setGuidanceScale] = useState(3.5);
  const [inferenceSteps, setInferenceSteps] = useState(28);
  const [aspectRatio, setAspectRatio] = useState("1:1");
  const [seed, setSeed] = useState(-1);  // -1 means random
  const [showSizeModal, setShowSizeModal] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [toast, setToast] = useState(null);
  const [highlightButton, setHighlightButton] = useState(false);

  useEffect(() => {
    const initializeProduct = async () => {
      if (location.state?.selectedProduct) {
        const product = location.state.selectedProduct;
        console.log('Initial product data:', product);

        if (!product.lora_file_url && product.request_id) {
          try {
            const apiKey = process.env.REACT_APP_FLUX_API_KEY;
            fal.config({ credentials: apiKey });

            const status = await fal.queue.status("fal-ai/flux-lora-fast-training", {
              requestId: product.request_id,
              logs: true
            });

            console.log('FAL API status:', status);

            if (status.status === 'COMPLETED') {
              const result = await fal.queue.result("fal-ai/flux-lora-fast-training", {
                requestId: product.request_id,
              });

              if (result?.diffusers_lora_file?.url) {
                const { data: updatedProduct, error } = await supabase
                  .from('products')
                  .update({ 
                    lora_file_url: result.diffusers_lora_file.url,
                    lora_model_path: result.diffusers_lora_file.url
                  })
                  .eq('id', product.id)
                  .select()
                  .single();

                if (!error && updatedProduct) {
                  console.log('Setting updated product:', updatedProduct);
                  setSelectedProduct(updatedProduct);
                  return;
                }
              }
            }
          } catch (error) {
            console.error('Error fetching FAL data:', error);
          }
        }

        console.log('Setting original product:', product);
        setSelectedProduct(product);
      }

      if (location.state?.guidanceImage) {
        setSelectedGuidanceImage(location.state.guidanceImage);
      }
    };

    initializeProduct();
  }, [location]);

  useEffect(() => {
    fetchIntroVideo();
    let channel;

    const fetchCreditsBalance = async () => {
      try {
        if (!user?.id) return;

        const { data, error } = await supabase
          .from('users')
          .select('credits_balance')
          .eq('id', user.id)
          .single();

        if (error) throw error;
        setCreditsBalance(data?.credits_balance ?? 0);

        channel = supabase
          .channel(`public:users:id=eq.${user.id}`)
          .on(
            'postgres_changes',
            {
              event: '*',
              schema: 'public',
              table: 'users',
              filter: `id=eq.${user.id}`
            },
            (payload) => {
              console.log('Credits update received:', payload);
              if (payload.new && typeof payload.new.credits_balance !== 'undefined') {
                setCreditsBalance(payload.new.credits_balance);
              }
            }
          )
          .subscribe();

      } catch (error) {
        console.error('Error fetching credits balance:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCreditsBalance();

    return () => {
      if (channel) {
        supabase.removeChannel(channel);
      }
    };
  }, [user?.id]);

  useEffect(() => {
    if (user?.id) {
      fetchUserGeneratedImages();
    }
  }, [user]);

  const fetchIntroVideo = async () => {
    try {
      const { data, error } = await supabase
        .from('app_variables')
        .select('video_url')
        .eq('title', 'video_intro')
        .single();

      if (error) throw error;
      
      if (data?.video_url) {
        console.log('Fetched new video URL:', data.video_url);
        setIntroVideoUrl(data.video_url);
      }
    } catch (error) {
      console.error('Error fetching intro video:', error);
    }
  };

  const fetchUserGeneratedImages = async () => {
    try {
      const { data, error } = await supabase
        .from('generated_images')
        .select(`
          id,
          user_id,
          product_id,
          image_url,
          prompt_description,
          created_at
        `)
        .eq('user_id', user.id)
        .order('created_at', { ascending: false });

      if (error) throw error;
      setUserGeneratedImages(data);
    } catch (error) {
      console.error('Error fetching generated images:', error);
    }
  };

  const handleContainerMouseDown = (e) => {
    setIsDraggingContainer(true);
    setStartDragX(e.pageX - containerRef.current.offsetLeft);
    setScrollLeftStart(containerRef.current.scrollLeft);
  };

  const handleContainerMouseMove = (e) => {
    if (!isDraggingContainer) return;
    e.preventDefault();
    const x = e.pageX - containerRef.current.offsetLeft;
    const walk = (x - startDragX) * 2;
    containerRef.current.scrollLeft = scrollLeftStart - walk;
  };

  const handleContainerMouseUp = () => {
    setIsDraggingContainer(false);
  };

  const handleImageDownload = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `generated-image-${Date.now()}.jpg`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading image:', error);
    }
  };

  const handleDeleteImage = async (imageUrl) => {
    try {
      const storageUrl = new URL(imageUrl);
      const filePath = storageUrl.pathname.split('/public/')[1];

      const { error: storageError } = await supabase.storage
        .from('generated_content')
        .remove([filePath]);

      if (storageError) throw storageError;

      const { error: dbError } = await supabase
        .from('generated_images')
        .delete()
        .eq('image_url', imageUrl);

      if (dbError) throw dbError;

      setUserGeneratedImages(prev => prev.filter(img => img.image_url !== imageUrl));
      
      if (expandedImage === imageUrl) {
        setExpandedImage(null);
      }
    } catch (error) {
      console.error('Error deleting image:', error);
    }
  };

  const VideoModal = ({ videoUrl, onClose }) => (
    <div 
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          onClose();
        }
      }}
      className="mobile-video-modal"
    >
      <div className="mobile-video-modal-content">
        <ReactPlayer
          url={videoUrl}
          width="100%"
          height="100%"
          playing={true}
          controls={true}
          muted={false}
          playsinline={true}
          config={{
            youtube: {
              playerVars: {
                showinfo: 1,
                modestbranding: 1,
                rel: 0,
                autoplay: 1
              }
            }
          }}
        />
        <button
          onClick={onClose}
          className="mobile-video-close-button"
        >
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="24" 
            height="24" 
            viewBox="0 0 24 24" 
            fill="none" 
            stroke="currentColor" 
            strokeWidth="2" 
            strokeLinecap="round" 
            strokeLinejoin="round"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
      </div>
    </div>
  );

  const handleUpgradeClick = () => {
    setShowPricing(true);
  };

  const fetchProductData = async (productId) => {
    try {
      // First fetch the product data
      const { data, error } = await supabase
        .from('products')
        .select(`
          id,
          name,
          image_url,
          images_urls,
          lora_file_url,
          lora_model_path,
          training_status,
          user_id,
          product_name,
          request_id,
          config_file_url,
          public_product
        `)
        .eq('id', productId)
        .single();

      if (error) throw error;
      if (!data) throw new Error('Producto no encontrado');

      console.log('Initial product data:', data);

      // Check if we already have a valid lora URL
      if (data.lora_file_url || data.lora_model_path) {
        return data;
      }

      // If product is READY but missing lora URLs, check FAL API
      if (data.training_status === 'READY' && data.request_id) {
        console.log('Checking FAL API for lora URL...');
        
        const apiKey = process.env.REACT_APP_FLUX_API_KEY;
        fal.config({ credentials: apiKey });

        // Get status first
        const status = await fal.queue.status("fal-ai/flux-lora-fast-training", {
          requestId: data.request_id,
          logs: true
        });

        console.log('FAL API status:', status);

        if (status.status === 'COMPLETED') {
          // Get the result
          const result = await fal.queue.result("fal-ai/flux-lora-fast-training", {
            requestId: data.request_id,
          });

          console.log('FAL API result:', result);

          if (result?.diffusers_lora_file?.url) {
            // Update both lora URLs in the database
            const { data: updatedProduct, error: updateError } = await supabase
              .from('products')
              .update({ 
                lora_file_url: result.diffusers_lora_file.url,
                lora_model_path: result.diffusers_lora_file.url // Update both fields
              })
              .eq('id', productId)
              .select()
              .single();

            if (updateError) throw updateError;
            
            console.log('Updated product with lora URL:', updatedProduct);
            return updatedProduct;
          }
        } else if (status.status === 'FAILED') {
          // If FAL training failed, update the product status
          await supabase
            .from('products')
            .update({ training_status: 'FAILED' })
            .eq('id', productId);
            
          throw new Error('El entrenamiento del producto falló');
        }
      }

      // If we get here, the product is not ready
      throw new Error('El producto no está listo para usar');

    } catch (error) {
      console.error('Error fetching product:', error);
      setError(error.message);
      return null;
    }
  };

  const handleGenerateImage = async () => {
    try {
      if (!user) {
        setShowAuthModal(true);
        return;
      }

      if (!selectedProduct) {
        setToast({
          type: 'error',
          message: 'Please select a product first',
          action: {
            label: 'Select Product',
            onClick: () => {
              setHighlightButton(true);
              setTimeout(() => setHighlightButton(false), 2000);
              setToast(null);
            }
          }
        });
        return;
      }

      if (!prompt.trim()) {
        setToast({
          type: 'error',
          message: 'Please enter a description for your image'
        });
        return;
      }

      setIsGenerating(true);
      setError(null);

      const imageUrl = await generateImage({
        prompt: prompt.trim(),
        user,
        selectedProduct: selectedProduct,
        guidanceImage: selectedGuidanceImage,
        guidanceScale,
        inferenceSteps,
        aspectRatio,
        seed,
        onInsufficientCredits: (required, current) => {
          setRequiredCredits(required);
          setCurrentBalance(current);
          setShowInsufficientCreditsModal(true);
        }
      });

      await fetchUserGeneratedImages();
      setSelectedGuidanceImage(null);

      // Add smooth scrolling to the generated images container
      const imagesContainer = document.querySelector('.mobile-generated-images');
      if (imagesContainer) {
        imagesContainer.scrollIntoView({ 
          behavior: 'smooth',
          block: 'start'
        });
      }

    } catch (error) {
      console.error('Error generating image:', error);
      setToast({
        type: 'error',
        message: error.message
      });
    } finally {
      setIsGenerating(false);
    }
  };

  const handleProductSelect = (product) => {
    setSelectedProduct(product);
  };

  const handleImageSelect = async (imageUrl) => {
    try {
      // Get the image data from the database to extract its seed
      const { data: imageData, error } = await supabase
        .from('generated_images')
        .select('seed')
        .eq('image_url', imageUrl)
        .single();

      if (error) throw error;

      // Set the seed if available, otherwise use a random seed
      if (imageData?.seed) {
        setSeed(parseInt(imageData.seed));
      }

      setSelectedGuidanceImage(imageUrl);
      
      navigate(location.pathname, {
        state: {
          ...location.state,
          guidanceImage: imageUrl
        },
        replace: true
      });

      // Scroll to the generate section
      const generateSection = document.querySelector('.mobile-feature-card');
      if (generateSection) {
        generateSection.scrollIntoView({ behavior: 'smooth' });
      }
    } catch (error) {
      console.error('Error setting guidance image:', error);
      setToast({
        type: 'error',
        message: 'Failed to set guidance image'
      });
    }
  };

  const handleClearGuidance = (e) => {
    e.stopPropagation();
    setSelectedGuidanceImage(null);
    
    const newState = { ...location.state };
    delete newState.guidanceImage;
    
    navigate(location.pathname, {
      state: newState,
      replace: true
    });
  };

  const SizeSelectModal = ({ isOpen, onClose, value, onChange }) => {
    return (
      <div 
        className={`mobile-size-modal ${isOpen ? 'open' : ''}`}
        onClick={onClose}
      >
        <div 
          className="mobile-size-modal-content"
          onClick={e => e.stopPropagation()}
        >
          <div className="mobile-size-modal-header">
            <button onClick={onClose}>Done</button>
          </div>
          <div className="mobile-size-options">
            {sizeOptions.map(option => (
              <button
                key={option.value}
                className={`mobile-size-option ${value === option.value ? 'active' : ''}`}
                onClick={() => {
                  onChange(option.value);
                  onClose();
                }}
              >
                {option.label}
                {value === option.value && (
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                )}
              </button>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const BurgerMenu = () => {
    const handleSignOut = async () => {
      try {
        await signOut();
        navigate('/auth');
      } catch (error) {
        console.error('Error signing out:', error);
        setToast({
          type: 'error',
          message: 'Failed to sign out. Please try again.'
        });
      }
    };

    const handleWhatsAppClick = () => {
      const phoneNumber = "59172135221";
      const message = encodeURIComponent("Hola, quiero comunicarme con el servicio al cliente de Grazia.ai");
      const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;
      window.open(whatsappUrl, '_blank');
    };

    return (
      <div className="mobile-burger-menu">
        <button 
          className="mobile-burger-button"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          aria-label="Menu"
        >
          <div className={`burger-icon ${isMenuOpen ? 'open' : ''}`}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>

        {isMenuOpen && (
          <div className="mobile-menu-overlay" onClick={() => setIsMenuOpen(false)}>
            <div 
              className="mobile-menu-content"
              onClick={e => e.stopPropagation()}
            >
              <div className="mobile-menu-header">
                <button 
                  className="mobile-menu-close"
                  onClick={() => setIsMenuOpen(false)}
                >
                  <X size={24} />
                </button>
              </div>
              <div className="mobile-menu-items">
                <button onClick={() => {
                  setIsMenuOpen(false);
                  navigate('/profile');
                }}>
                  Profile
                </button>
                <button 
                  onClick={handleWhatsAppClick}
                  className="mobile-menu-whatsapp"
                >
                  <span>Servicio al cliente</span>
                  <MessageCircle size={16} />
                </button>
                <button 
                  onClick={handleSignOut}
                  style={{
                    color: '#FF4444'
                  }}
                >
                  Sign Out
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const createButtonClass = `mobile-create-button ${highlightButton ? 'highlight-button' : ''}`;

  const ExpandedImageModal = ({ imageUrl, onClose }) => (
    <div 
      className="mobile-expanded-image-modal"
      onClick={onClose}
    >
      <div 
        className="mobile-expanded-image-content"
        onClick={e => e.stopPropagation()}
      >
        <img 
          src={imageUrl} 
          alt="Expanded view"
          className="mobile-expanded-image"
        />
        <button
          className="mobile-expanded-image-close"
          onClick={onClose}
          aria-label="Close expanded image"
        >
          <X size={24} />
        </button>
        <button
          className="mobile-download-button"
          onClick={(e) => {
            e.stopPropagation();
            handleImageDownload(imageUrl);
          }}
          style={{ 
            position: 'absolute',
            bottom: '16px',
            right: '16px',
            opacity: 1 
          }}
        >
          Download
          <Download size={14} />
        </button>
      </div>
    </div>
  );

  const Footer = () => {
    const currentYear = new Date().getFullYear();
    
    return (
      <footer className="mobile-footer">
        <div className="mobile-footer-content">
          <div className="mobile-footer-section">
            <h4>Signotech LLC</h4>
            <p>Transformando el futuro del diseño con inteligencia artificial</p>
          </div>
          
          <div className="mobile-footer-section">
            <h4>Contacto</h4>
            <a href="mailto:contact@grazia.ai">contact@grazia.ai</a>
            <a href="tel:+12819359448">+1 281-935-9448</a>
            <p>Delaware, United States</p>
          </div>
          
          <div className="mobile-footer-section">
            <h4>Legal</h4>
            <a href="/terms">Términos y Condiciones</a>
            <a href="/privacy">Política de Privacidad</a>
            <a href="/cookies">Política de Cookies</a>
          </div>
          
          <div className="mobile-footer-bottom">
            <p>&copy; {currentYear} Signotech LLC. Todos los derechos reservados.</p>
          </div>
        </div>
      </footer>
    );
  };

  return (
    <div className="mobile-main">
      <div className="mobile-header">
        <div className="mobile-logo">Grazia</div>
        <div className="mobile-actions">
          <div className="mobile-credits">
            {loading ? 'Loading...' : `${creditsBalance} Credits`}
          </div>
          <button 
            onClick={handleUpgradeClick} 
            className="mobile-upgrade-button"
          >
            Upgrade
          </button>
          <BurgerMenu />
        </div>
      </div>

      <div className="mobile-content">
        <div className="mobile-feature-card video-card">
          <div className="mobile-video-wrapper">
            {introVideoUrl && (
              <>
                <ReactPlayer
                  url={introVideoUrl}
                  width="100%"
                  height="100%"
                  playing={true}
                  loop={true}
                  muted={true}
                  playsinline={true}
                  config={{
                    youtube: {
                      playerVars: {
                        showinfo: 0,
                        controls: 0,
                        modestbranding: 1,
                        rel: 0,
                        autoplay: 1
                      }
                    }
                  }}
                />
                <div 
                  className="mobile-video-overlay"
                  onClick={() => setShowVideoModal(true)}
                >
                  <div className="mobile-video-text">
                    Comienza aquí...
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        <div className="mobile-feature-card">
          <h3>Product Studio</h3>
          <p>Selecciona un producto de prueba para comenzar o entrena nuestro modelo AI con imagenes de tu producto</p>
          <button 
            className={createButtonClass}
            onClick={(e) => {
              e.stopPropagation();
              navigate('/select-product');
            }}
          >
            <span>CREAR NUEVO</span>
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              width="20" 
              height="20" 
              viewBox="0 0 24 24" 
              fill="none" 
              stroke="currentColor" 
              strokeWidth="2" 
              strokeLinecap="round" 
              strokeLinejoin="round"
            >
              <circle cx="12" cy="12" r="10"></circle>
              <line x1="12" y1="8" x2="12" y2="16"></line>
              <line x1="8" y1="12" x2="16" y2="12"></line>
            </svg>
          </button>
        </div>

        <div className="mobile-feature-card">
          <h3>Genera Imágenes</h3>
          <p>Las imágenes se crearan usando el producto que tengas seleccionado arriba</p>
          
          {selectedProduct && (
            <div 
              className="mobile-selected-product"
              onClick={(e) => {
                e.stopPropagation();
                navigate('/select-product');
              }}
              style={{
                cursor: 'pointer',
                transition: 'transform 0.2s ease, opacity 0.2s ease'
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.transform = 'scale(1.02)';
                e.currentTarget.style.opacity = '0.9';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.transform = 'scale(1)';
                e.currentTarget.style.opacity = '1';
              }}
            >
              <img 
                src={selectedProduct.image_url} 
                alt={selectedProduct.name}
                className="mobile-product-thumbnail"
              />
              <span className="mobile-product-name">{selectedProduct.name}</span>
            </div>
          )}

          {selectedGuidanceImage && (
            <div className="mobile-selected-product">
              <img 
                src={selectedGuidanceImage} 
                alt="Imagen de guía"
                className="mobile-product-thumbnail"
              />
              <span className="mobile-product-name">Imagen de guía</span>
              <button 
                className="mobile-clear-guidance"
                onClick={handleClearGuidance}
              >
                <X size={16} />
              </button>
            </div>
          )}

          <div className="mobile-input-group">
            <label>Describe tu imagen</label>
            <textarea
              placeholder="Iluminacion volumetrica, hiper realismo, color grade dramatico"
              className="mobile-prompt-input"
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
            />
          </div>

          <div className="mobile-control-group">
            <label>Tamaño de imagen</label>
            <button 
              className="mobile-size-select-button"
              onClick={() => setShowSizeModal(true)}
            >
              {sizeOptions.find(opt => opt.value === aspectRatio)?.label}
              <ChevronDown size={16} />
            </button>
            
            <SizeSelectModal
              isOpen={showSizeModal}
              onClose={() => setShowSizeModal(false)}
              value={aspectRatio}
              onChange={setAspectRatio}
            />
          </div>

          <div className="mobile-input-group">
            <button 
              className="mobile-advanced-toggle"
              onClick={() => setShowAdvancedControls(!showAdvancedControls)}
            >
              <span>Ver controles aburridos</span>
              <ChevronDown 
                size={16} 
                style={{ 
                  transform: showAdvancedControls ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'transform 0.2s ease'
                }} 
              />
            </button>

            {showAdvancedControls && (
              <div className="mobile-advanced-controls">
                <div className="mobile-control-group">
                  <label>Guidance Scale</label>
                  <div className="value-display">{guidanceScale}</div>
                  <input
                    type="range"
                    min="1"
                    max="20"
                    step="0.1"
                    value={guidanceScale}
                    onChange={(e) => {
                      const value = Number(e.target.value);
                      setGuidanceScale(value);
                      // Update progress visualization
                      const progress = ((value - 1) / (20 - 1)) * 100;
                      e.target.style.setProperty('--range-progress', `${progress}%`);
                    }}
                    style={{
                      '--range-progress': `${((guidanceScale - 1) / (20 - 1)) * 100}%`
                    }}
                  />
                </div>

                <div className="mobile-control-group">
                  <label>Inference Steps</label>
                  <div className="value-display">{inferenceSteps}</div>
                  <input
                    type="range"
                    min="20"
                    max="50"
                    step="1"
                    value={inferenceSteps}
                    onChange={(e) => {
                      const value = Math.min(Number(e.target.value), 50);
                      setInferenceSteps(value);
                      // Update progress visualization
                      const progress = ((value - 20) / (50 - 20)) * 100;
                      e.target.style.setProperty('--range-progress', `${progress}%`);
                    }}
                    style={{
                      '--range-progress': `${((inferenceSteps - 20) / (50 - 20)) * 100}%`
                    }}
                  />
                </div>

                <div className="mobile-control-group">
                  <label>Seed</label>
                  <div className="mobile-seed-input">
                    <input
                      type="number"
                      value={seed === -1 ? '' : seed}
                      onChange={(e) => setSeed(e.target.value === '' ? -1 : Number(e.target.value))}
                      placeholder="Random"
                    />
                    <button onClick={() => setSeed(-1)}>
                      Random
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>

          <button 
            className="mobile-generate-button"
            onClick={handleGenerateImage}
            disabled={isGenerating}
          >
            <span>{isGenerating ? 'GENERANDO...' : 'GENERAR IMAGEN'}</span>
            {isGenerating ? (
              <svg 
                className="loading-spinner"
                xmlns="http://www.w3.org/2000/svg" 
                width="20" 
                height="20" 
                viewBox="0 0 24 24" 
                fill="none" 
                stroke="currentColor" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
              >
                <line x1="12" y1="2" x2="12" y2="6"/>
                <line x1="12" y1="18" x2="12" y2="22"/>
                <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"/>
                <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"/>
                <line x1="2" y1="12" x2="6" y2="12"/>
                <line x1="18" y1="12" x2="22" y2="12"/>
                <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"/>
                <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"/>
              </svg>
            ) : (
              <Aperture size={20} />
            )}
          </button>
        </div>
      </div>

      {userGeneratedImages && userGeneratedImages.length > 0 && (
        <div 
          ref={containerRef}
          className="mobile-generated-images"
          onMouseDown={handleContainerMouseDown}
          onMouseMove={handleContainerMouseMove}
          onMouseUp={handleContainerMouseUp}
          onMouseLeave={handleContainerMouseUp}
        >
          <div className="mobile-images-container">
            {userGeneratedImages.map((image, index) => (
              <div key={index} className="mobile-image-card">
                <div 
                  className="mobile-image-wrapper"
                  style={{
                    backgroundImage: `url(${image.image_url})`
                  }}
                  onClick={() => setExpandedImage(image.image_url)}
                >
                  <button 
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteImage(image.image_url);
                    }}
                    className="mobile-delete-button"
                    style={{ opacity: 1 }}
                  >
                    <X size={14} />
                  </button>

                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleImageDownload(image.image_url);
                    }}
                    className="mobile-download-button"
                    style={{ opacity: 1 }}
                  >
                    Download
                    <Download size={14} />
                  </button>
                </div>
                
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleImageSelect(image.image_url);
                  }}
                  className="mobile-generate-more"
                >
                  <span>Generar más como esta</span>
                  <Zap size={14} />
                </button>
              </div>
            ))}
          </div>
        </div>
      )}

      {expandedImage && (
        <ExpandedImageModal
          imageUrl={expandedImage}
          onClose={() => setExpandedImage(null)}
        />
      )}

      {showVideoModal && (
        <VideoModal 
          videoUrl={introVideoUrl} 
          onClose={() => setShowVideoModal(false)} 
        />
      )}

      {showInsufficientCreditsModal && (
        <InsufficientCreditsModal
          onClose={() => setShowInsufficientCreditsModal(false)}
          onUpgrade={handleUpgradeClick}
          requiredCredits={requiredCredits}
          currentBalance={currentBalance}
        />
      )}

      {error && (
        <div className="mobile-error-message">
          {error}
        </div>
      )}

      {showAuthModal && (
        <AuthModal onClose={() => setShowAuthModal(false)} />
      )}

      {showPricing && (
        <MobilePricingPlansModal 
          onClose={() => setShowPricing(false)}
        />
      )}

      {toast && (
        <Toast
          message={toast.message}
          type={toast.type}
          action={toast.action}
          onClose={() => setToast(null)}
        />
      )}

      <Footer />
    </div>
  );
};

export default MobileMainPage; 