import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Auth from './components/Auth';
import { AuthProvider, useAuth } from './context/AuthContext';
import PaymentSuccess from './components/PaymentSuccess';
import PaymentCancelled from './components/PaymentCancelled';
import MainPage from './components/MainPage';
import { CreditsProvider } from './context/CreditsContext';
import Profile from './components/Profile';
import MobileScreen from './mobile/MobileScreen';
import MobileMainPage from './mobile/MobileMainPage';
import MobileProductMenu from './mobile/MobileProductMenu';
import MobileProfile from './mobile/MobileProfile';
import MobileAuth from './mobile/MobileAuth';
import LegalPage from './pages/LegalPage';

// Protected Route component
const ProtectedRoute = ({ children }) => {
  const { user, loading } = useAuth();
  
  if (loading) {
    return <div className="loading-screen">Loading...</div>;
  }
  
  if (!user) {
    return <Navigate to="/auth" replace />;
  }
  
  return children;
};

// Root component to handle initial routing
const RootComponent = () => {
  const { user, loading } = useAuth();

  if (loading) {
    return <div className="loading-screen">Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/auth" replace />;
  }

  return <Navigate to="/select-product" replace />;
};

function App() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  // Create responsive components
  const ResponsiveMainPage = () => {
    return isMobile ? <MobileMainPage /> : <MainPage />;
  };

  const ResponsiveProfile = () => {
    return isMobile ? <MobileProfile /> : <Profile />;
  };

  // Add responsive auth component
  const ResponsiveAuth = () => {
    return isMobile ? <MobileAuth /> : <Auth />;
  };

  return (
    <Router>
      <AuthProvider>
        <CreditsProvider>
          <Routes>
            <Route path="/auth" element={<ResponsiveAuth />} />
            <Route path="/main" element={
              <ProtectedRoute>
                <ResponsiveMainPage />
              </ProtectedRoute>
            } />
            <Route path="/profile" element={
              <ProtectedRoute>
                <ResponsiveProfile />
              </ProtectedRoute>
            } />
            <Route path="/select-product" element={
              <ProtectedRoute>
                <MobileProductMenu />
              </ProtectedRoute>
            } />
            <Route path="/" element={<RootComponent />} />
            <Route path="*" element={<Navigate to="/" replace />} />
            <Route path="/payment-success" element={<PaymentSuccess />} />
            <Route path="/payment-cancelled" element={<PaymentCancelled />} />
            <Route path="/terms" element={<LegalPage initialSection="terms" />} />
            <Route path="/privacy" element={<LegalPage initialSection="privacy" />} />
            <Route path="/cookies" element={<LegalPage initialSection="cookies" />} />
          </Routes>
        </CreditsProvider>
      </AuthProvider>
    </Router>
  );
}

export default App; 